import { Axios } from './../shared/Axios';
import qs from 'qs';

class OrderService extends Axios {

    private static instance: OrderService;

    static get(): OrderService {
        if (!OrderService.instance) {
            OrderService.instance = new OrderService();
        }
        return OrderService.instance;
    }

    async getFoods(): Promise<any> {
        return this.instance.get(`/orders/foods`)
    }

    async getOrderTables(): Promise<any> {
        return this.instance.get(`/orders/tables`)
    }

    async printOrderReceipt(tableId: string): Promise<any> {
        return this.instance.post(`/orders/receipt/${tableId}`)
    }

    async getOrderProducts(tableId: string): Promise<any> {
        return this.instance.get(`/orders/${tableId}`)
    }

    async savePaymentOrder(data: object): Promise<any> {
        return this.instance.post(`/orders/payment`, data)
    }


}


const orderService = OrderService.get();

export { orderService as OrderService }