import { Axios } from './../shared/Axios';
import qs from 'qs';
import FileSaver from 'file-saver';
import moment from 'moment';


class StockService extends Axios {

    private static instance: StockService;

    static get(): StockService {
        if (!StockService.instance) {
            StockService.instance = new StockService();
        }
        return StockService.instance;
    }

    async getStocks(data: object = {}): Promise<any> {
        return this.instance.get(`/stocks${qs.stringify(data, { addQueryPrefix: true })}`)
    }

    async getStockMenusConfig(data: object = {}): Promise<any> {
        return this.instance.get(`/stocks/menu_config${qs.stringify(data, { addQueryPrefix: true })}`)
    }

    async saveStock(data: object): Promise<any> {
        return this.instance.post(`/stocks`, data)
    }
    
    async deleteStockMenuConfig(menuconfig_id: string): Promise<any> {
        return this.instance.delete(`/stocks/menu_config/${menuconfig_id}`)
    }

    async saveStockConfig(data: object): Promise<any> {
        return this.instance.post(`/stocks/menu_config`, data)
    }

    async updateStockMenuConfig(menuconfig_id: string, data: object): Promise<any> {
        return this.instance.patch(`/stocks/menu_config/${menuconfig_id}`, data)
    }

    async deleteStock(stockId: string): Promise<any> {
        return this.instance.delete(`/stocks/${stockId}`)
    }

    async resetStock(): Promise<any> {
        return this.instance.post(`/stocks/reset`)
    }

    async uploadStock(formData: FormData): Promise<any> {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': "application/json"
            }
        };
        return this.instance.post(`/stocks/upload_excel`,formData, config)

    }

    async downloadStock(): Promise<any> {

        return this.instance.post(`/stocks/export`, {}, {responseType: 'arraybuffer'}).then((res:any) => {
            var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
            FileSaver.saveAs(blob, `report_${moment().format('DDMMMYYYYHHmm')}.xlsx`)
            return res;
        })

    }


}


const stockService = StockService.get();

export { stockService as StockService }