import { Box, Button, Card, Grid, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import FaceData from './faceCostomer.json'
import axios from 'axios'
function ManagementSell() {
    const [log, setLog] = useState<any>([])

    const [open, setOpen] = React.useState(false);
    const [info, setInfo] = React.useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/log/getlog`)
            .then(res => setLog(res.data.message))
    }, [])
    const handleOpen = (list: any) => {
        setOpen(true)
        setInfo(list)
    };
    const handleClose = () => setOpen(false);
    const options : any = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'Asia/Bangkok' // กำหนด timezone ตามต้องการ
      };

    const inputremovepading = {
        style: {
            padding: 5
        }
    }
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius:'12px'
    };

    return (
        <Grid container>
            <Grid sm={12} sx={{ bgcolor: '#FFB802', p: 3, width: '100%' }}>
                <Typography
                    sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}
                >
                    รายการขาย
                </Typography>
            </Grid>
            <Card sx={{ width: "100%", m: "6px" }}>
                <Grid container justifyContent="space-between" sx={{ p: 2 }}>
                    <Typography variant='h6' sx={{ color: 'white' }}>
                        ค้นหา
                    </Typography>
                    <Button variant='contained' sx={{ bgcolor: "#38DF67", color: 'white' }}>ดาวโหลดข้อมูลไฟล์ Exel</Button>
                </Grid>
                <Grid container p={1.5}>
                    <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>เลขที่ขาย</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>รหัสสินค้า</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={3} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>ประเภทการขาย</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                </Grid>
                <Grid container p={1.5} >
                    <Grid xs={10} sm={2.5} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>วันที่ทำการขาย</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={2.5} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1'>ลูกค้า</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={12} sm={12} lg={6} container justifyContent='space-around' >
                        <Grid xs={10} sm={3} lg={3} sx={{ m: 1 }}>
                            <Typography variant='subtitle1'>สถานะ</Typography>
                            <TextField placeholder="ทั้งหมด" inputProps={inputremovepading} variant='outlined' />
                        </Grid>
                        <Grid xs={10} sm={5} lg={3} sx={{ m: 1 }}>
                            <Typography variant='subtitle1'>ประเภทการชำระเงิน</Typography>
                            <TextField placeholder="ทั้งหมด" inputProps={inputremovepading} variant='outlined' />
                        </Grid>
                        <Grid xs={10} sm={2} lg={3} sx={{ m: 1 }}>
                            <Typography sx={{ visibility: "hidden" }} variant='subtitle1'>.</Typography>
                            <Button variant='contained' sx={{ bgcolor: "#FF9B28", width: "100%" }}>ค้นหา</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <Card sx={{ width: "100%", m: "6px" }}>
                <TableContainer component={Paper} sx={{ minHeight: 320 }}>
                    <Table sx={{ m: "6px", minWidth: 700 }}>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#e5e5e5" }}>
                                <TableCell align="center">เลขที่ขาย</TableCell>
                                <TableCell align="center">ลูกค้า</TableCell>
                                <TableCell align="center">จำนวน (รายการ)</TableCell>
                                <TableCell align="center">การชำระ</TableCell>
                                <TableCell align="center">จำนวนเงิน</TableCell>
                                <TableCell align="center">ส่วนลด / คะแนน</TableCell>
                                <TableCell align="center">เงินสุทธิ</TableCell>
                                <TableCell align="center">รับเงิน</TableCell>
                                <TableCell align="center">เงินทอน</TableCell>
                                <TableCell align="center">จัดการ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                log.map((data: any) => (
                                    <TableRow>
                                        <TableCell align="center">{data.id} <br/> <small>{new Date(data.createdAt).toLocaleString('en-US', options)}</small></TableCell>
                                        <TableCell align="center">ทั่วไป</TableCell>
                                        <TableCell align="center">{JSON.parse(data.list).reduce((accumulator: number, currentValue: any) => {
                                            return accumulator + currentValue.amount
                                        }, 0)}</TableCell>
                                        <TableCell align="center">เงินสด</TableCell>
                                        <TableCell align="center">{data.total_price}</TableCell>
                                        <TableCell align="center">{data.discont} %</TableCell>
                                        <TableCell align="center">{data.total_price - (data.total_price / 100 * data.discont)}</TableCell>
                                        <TableCell align="center">{data.amount_payment}</TableCell>
                                        <TableCell align="center">{data.amount_payment - (data.total_price - data.discont)}</TableCell>
                                        <TableCell align="center">
                                            <Button variant='contained'
                                                sx={{ bgcolor: "#00BCF7", color: 'white', borderRadius: "16px" }}
                                                onClick={() => handleOpen(JSON.parse(data.list))}>
                                                ดูลายละเอียด
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <Typography id="modal-modal-title" variant="h6" component="h2" textAlign='center'>
                        รายละเอียด
                    </Typography>
                    {
                        info.map((data: any) => (
                            <>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    {data.product_name}
                                </Typography>
                                <Grid container alignItems='center' justifyContent='space-between'>
                                    <img src={data.imageUrl} alt={data.product_name} width='100px' style={{borderRadius:'12px'}}/>
                                    <p> x {data.amount} </p>
                                    <p> {data.price}  บาท</p>
                                </Grid>
                            </>
                        ))
                    }
                </Box>
            </Modal>
        </Grid>

    )
}

export default ManagementSell