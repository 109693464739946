import { Box, Button, ButtonBase, Grid, Paper, Stack, Typography } from "@mui/material";
import CustomInputText from "../../../components/InputText/CustomInputText";
import StockTable from "../../../components/StockManagement/DeductionStockSetting/StockTable";
import CustomSelect from "../../../components/Select/CustomSelect";
import { StockService } from "../../../services/StockService";
import React from "react";
import Filters from "./Filters";

export default function AddStock() {

  const [stocks, setStocks] = React.useState<any[]>([])

  React.useEffect(() => {
    getStocks();
  }, [])

  const getStocks = async () => {
    const res = await StockService.getStockMenusConfig();
    if (res?.status == 200) {
      const { stockMenus } = res.response;
      setStocks(stockMenus)
    }
  }


  return (
    <>
      <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 600,
            fontSize: { xs: "18px", md: "24px" },
          }}
        >
          ตั้งค่าตัดสต๊อก
        </Typography>
      </Grid>
      <Paper sx={{ p: 2 }}>

        <Filters
          onResponse={stocks => setStocks(stocks)}
        ></Filters>
        <Box sx={{ mt: 4 }}>
          <StockTable
            stocks={stocks}
          />
        </Box>
      </Paper>
    </>
  );
}
