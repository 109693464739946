import styled from "@emotion/styled";
import { LinearProgress, List, ListItem, ListItemText, linearProgressClasses } from "@mui/material";

interface Props {
    titleLeft: string | any
    titleRight: string | any
    isProgressBar?: boolean
    progressColor?: string
    progressValue?: string
    disablePadding?: true
}


const ItemList = (props: Props) => {


    const BorderLinearProgress = styled(LinearProgress)(({ theme }: any) => ({
        height: 3,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? props.progressColor || 'blue' : '#308fe8',
        },
    }));

    return (
        <List disablePadding={props.disablePadding}>
            <ListItem
                disablePadding={props.disablePadding}
                disableGutters
                secondaryAction={
                    props.titleRight
                }
            >
                <ListItemText
                    primary={props.titleLeft}
                    primaryTypographyProps={{
                        fontSize: 14
                    }}
                />
            </ListItem>
            {
                props.isProgressBar ?
                    <center>
                        <BorderLinearProgress
                            variant="determinate"
                            value={props?.progressValue || 70}
                            style={{ width: '98%' }} />
                    </center>
                    :
                    null
            }

        </List >
    )
}
export default ItemList;