import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StockTableBody from "./StockTableBody";
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { Box, IconButton, Stack } from "@mui/material";
import TaskList from "../../Icon/TaskList";
import Stock from "../../../models/stock";
import React from "react";
import CustomEditModal from "./CustomEditModal";
import _ from "lodash";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E7E8F2",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "114px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ".MuiTableCell-root": {
    borderLeft: "1px solid #D9D9D9",
  },
}));



interface Props {
  stocks: any[]
}

export default function StockTable(props: Props) {

  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [stock, setStock] = React.useState<Stock>();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "รหัสสินค้า",
      type: "string",
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "รายการสินค้า",
      type: "string",
      disableColumnMenu: true,
    },
    {
      field: "cutStockDescription",
      headerName: "ตัดสต๊อก",
      disableColumnMenu: true,
    },
    {
      field: "cost",
      headerName: "ต้นทุน",
      type: "string",
      disableColumnMenu: true,
    },
    {
      field: "price",
      headerName: "ราคาขาย",
      type: "string",
      disableColumnMenu: true,
    },
    {
      field: "profit",
      headerName: "กำไร",
      type: "string",
      disableColumnMenu: true
    },
    // {
    //   field: "sell",
    //   headerName: "ขายได้",
    //   type: "string",
    //   minWidth: 120,
    //   disableColumnMenu: true,
    //   renderCell: () => {
    //     return '0'
    //   }
    // },
    // {
    //   field: "unit",
    //   headerName: "หน่วย",
    //   type: "string",
    //   minWidth: 120,
    //   disableColumnMenu: true
    // },
    {
      field: "จัดการ",
      headerName: "จัดการ",
      type: "string",
      disableColumnMenu: true,
      renderCell: (row: any) => (
        <Stack direction="row" justifyContent="center" sx={{ flexGrow: 1, p: 1 }}>
          <IconButton
            onClick={() => {
              setStock(row.row);
              setIsOpenModal(true)
            }}
            sx={{
              bgcolor: "#FFB969",
              borderRadius: "8px",
              ":hover": { bgcolor: "#FFB969" },
              mr: 2,
            }}
          >
            <TaskList />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    // <TableContainer component={Paper}>
    //   <Table
    //     className="tableBorder"
    //     sx={{ minWidth: 700 }}
    //     aria-label="customized table"
    //   >
    //     <TableHead>
    //       <StyledTableRow>
    //         <StyledTableCell>รหัสสินค้า</StyledTableCell>
    //         <StyledTableCell align="center">รายการสินค้า</StyledTableCell>
    //         <StyledTableCell align="center">ตัดสต๊อก</StyledTableCell>
    //         <StyledTableCell align="center">ต้นทุน</StyledTableCell>
    //         <StyledTableCell align="center">ราคาขาย</StyledTableCell>
    //         <StyledTableCell align="center">กำไร</StyledTableCell>
    //         <StyledTableCell align="center">ขายได้</StyledTableCell>
    //         <StyledTableCell align="center">หน่วย</StyledTableCell>
    //         <StyledTableCell align="center">จัดการ</StyledTableCell>
    //       </StyledTableRow>
    //     </TableHead>
    //     <StockTableBody />
    //   </Table>
    // </TableContainer>
    <>
      {stock &&
        <CustomEditModal
          isOpen={isOpenModal}
          stocks={props.stocks}
          stock={stock}
          onClose={() => setIsOpenModal(false)}
        />
      }
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100, 500]}
          sx={{ width: { xs: 700, sm: 700, md: '100%' } }}
          getRowId={(row: GridRowModel) => row.id}
          getRowHeight={() => 'auto'}
          showColumnVerticalBorder
          showCellVerticalBorder
          rowSelection={false}
          rows={props.stocks}
          columns={columns}
        // checkboxSelection
        />
      </div>
    </>
  );
}
