import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import CustomInputText from "../../../components/InputText/CustomInputText";
import StockTable from "../../../components/StockManagement/StockRemaining/StockTable";
import ButtonList from "../../../components/StockManagement/StockRemaining/ButtonList";
import React from "react";
import { StockService } from "../../../services/StockService";
import { Download, PlayArrow, Print } from "@mui/icons-material";
import Swal from 'sweetalert2';
import Filters from "./Filters";

export default function StockRemainning() {

  const [stocks, setStocks] = React.useState<any[]>([])

  React.useEffect(() => {
    getStocks();
  }, [])

  const getStocks = async () => {
    const res = await StockService.getStocks();
    if (res?.status == 200) {
      const { stocks } = res.response;
      setStocks(stocks)
    }
  }

  const deleteStock = async (stockId: string) => {
    const res = await StockService.deleteStock(stockId);
    if (res?.status == 200) {
      getStocks();
    }
  }

  const downloadStock = async () => {
    try {
      const response = await StockService.downloadStock();

      // Create a blob URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'output.xlsx'); // Set the filename
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }

  }

  const resetStock = () => {
    Swal.fire({
      title: "ยืนยันหรือไม่ ?",
      text: `แน่ใจหรือไม่ ว่าจะรีเซ็ตสต๊อกคงเหลือ`,
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "ใช่, ลบเลย!",
      cancelButtonText: "ไม่, ยกเลิก!"
    }).then(async (result) => {
      if (result.isConfirmed) {

        const res = await StockService.resetStock()

        if (res?.status == 200) {
          Swal.fire({
            icon: "success",
            text: res?.message
          });
        } else {
          Swal.fire({
            icon: "error",
            text: res?.message
          });
        }
      }
    })
  }



  return (
    <>
      <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 600,
            fontSize: { xs: "18px", md: "24px" },
          }}
        >
          สต๊อกคงเหลือ
        </Typography>
      </Grid>

      <Paper sx={{ p: 2 }}>

        <Grid container spacing={2} display={'flex'} justifyContent={'flex-end'} >
          <Grid item >
            <Button onClick={() => resetStock()} variant="contained" sx={{ color: "white", bgcolor: "#FF6F6F" }}>
              รีเซ็ทสต๊อกคงเหลือ
            </Button>
          </Grid>
          <Grid item >
            <Button
              onClick={() => downloadStock()}
              variant="contained"
              sx={{ bgcolor: "#38DF67", color: "white" }}
              startIcon={<Download />}
            >
              ดาวโหลดข้อมูลไฟล์ Excel
            </Button>
          </Grid>
          {/* <Grid item >
            <Button
              variant="contained"
              sx={{ bgcolor: "#38DF67", color: "white" }}
              startIcon={<Download />}
            >
              ดาวโหลดใบสั่งสินค้า
            </Button>

          </Grid> */}
        </Grid>
        <br></br>
        <Filters
          onResponse={stocks => setStocks(stocks)}
        ></Filters>
        <Box sx={{ mt: 4 }}>
          <StockTable
            stocks={stocks}
            onDelete={deleteStock}
          />
        </Box>
      </Paper>
    </>
  );
}
