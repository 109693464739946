import React, { useImperativeHandle, forwardRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AddBox, Delete } from "@mui/icons-material";
import TaskList from "../../Icon/TaskList";
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomInputText from "../../InputText/CustomInputText";
import CustomSelect from "../../Select/CustomSelect";
import _ from "lodash";
import Autocomplete from "./Autocomplete";
import InputText from "./InputText";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E7E8F2",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "114px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ".MuiTableCell-root": {
    borderLeft: "1px solid #D9D9D9",
  },
}));

interface Props {
  stocks: any[];
}

interface RowProps {
  id: number;
  name?: string;
  addQuantity?: number;
  quantity?: number;
  barcode?: string;
  remain?: number;
  costPrice?: number;
}

const StockTable = (props: Props, ref: any) => {
  const [summary, setSummary] = React.useState<{
    totalCost: number;
    totalQuantity: number;
    totalInStock: number;
    totalRemain: number;
  }>({
    totalCost: 0,
    totalQuantity: 0,
    totalInStock: 0,
    totalRemain: 0,
  });
  const [rows, setRows] = React.useState<RowProps[]>([{ id: 0 }]);

  useImperativeHandle(ref, () => ({
    getResults: () => {
      return rows;
    },
  }));

  const searchBarcode = (barcode: string | null, index: number) => {
    const foundItem = _.find(props.stocks, { barcode: barcode });
    if (foundItem) {
      setRows((p) => {
        p[index] = foundItem;
        return [...p];
      });
    }
  };

  const onValueChange = (value: string, type: string, index: number) => {
    if (type == "name") {
      rows[index]["name"] = value;
      setRows(rows);
      return;
    } else if (type == "barcode") {
      rows[index]["barcode"] = value;
      setRows(rows);
      return;
    }

    if (type == "addQuantity") {
      const remain =
        parseFloat(value || "0") + parseFloat(`${rows[index].quantity || "0"}`);

      rows[index]["addQuantity"] = parseFloat(value || "0");
      rows[index]["remain"] = remain;
      setRows([...rows]);
    } else if (type == "costPrice") {
      rows[index]["costPrice"] = parseFloat(value || "0");
      setRows(rows);
    }

    calculateSummary(rows);
  };

  const handleAddRow = () => {
    const newRow = { id: 0 };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (index: number) => {
    if (rows.length > 1) {
      rows.splice(index, 1);
      setRows([...rows]);
    }
  };

  const calculateSummary = (rows: RowProps[]) => {
    const totalCost: any[] = [];
    const totalQuantity: any[] = [];
    const totalInStock: any[] = [];
    const totalRemain: any[] = [];

    rows.forEach((item) => {
      totalCost.push(item.costPrice);
      totalQuantity.push(item.addQuantity);
      totalInStock.push(item.quantity);
      totalRemain.push(item.remain);
    });
    setSummary({
      totalCost: _.sum(totalCost),
      totalQuantity: _.sum(totalQuantity),
      totalInStock: _.sum(totalInStock),
      totalRemain: _.sum(totalRemain),
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table
        className="tableBorder"
        sx={{ minWidth: 700 }}
        aria-label="customized table"
      >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>ลำดับ</StyledTableCell>
            <StyledTableCell align="center">รหัสบาร์โค้ด</StyledTableCell>
            <StyledTableCell align="center">รายการ</StyledTableCell>
            <StyledTableCell align="center">ราคาต้นทุน</StyledTableCell>
            <StyledTableCell align="center">จำนวน</StyledTableCell>
            <StyledTableCell align="center">ในสต๊อก</StyledTableCell>
            <StyledTableCell align="center">คงเหลือ</StyledTableCell>
            <StyledTableCell align="center">จัดการ</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row" align="center">
                <AddBox
                  onClick={handleAddRow}
                  sx={{ color: "#384042", borderRadius: "8px" }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <Autocomplete
                  onBlur={(newValue: string) => {
                    onValueChange(newValue, "barcode", index);
                  }}
                  onChange={(newValue: string | null) => {
                    if (newValue) {
                      searchBarcode(newValue, index);
                    } else {
                      rows[index] = { id: 0 };
                      setRows([...rows]);
                    }
                  }}
                  onDelete={() => {
                    rows[index] = { id: 0 };
                    setRows([...rows]);
                  }}
                  barcode={`${row.barcode || ""}`}
                  stocks={props.stocks}
                ></Autocomplete>
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.id == 0 ? (
                  <InputText
                    onChange={(value: string) => {
                      onValueChange(value, "name", index);
                    }}
                  />
                ) : (
                  row.name
                )}
              </StyledTableCell>
              <StyledTableCell align="center">
                <InputText
                  onKeyPress={(event) => {
                    if (!/[0-9\.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(value: string) => {
                    onValueChange(value, "costPrice", index);
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <InputText
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(value: string) => {
                    onValueChange(value, "addQuantity", index);
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.quantity || 0}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.remain || 0}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{ flexGrow: 1 }}
                >
                  {/* <IconButton
                    sx={{
                      bgcolor: "#FFB969",
                      borderRadius: "8px",
                      ":hover": { bgcolor: "#FFB969" },
                      mr: 2,
                    }}
                  >
                    <TaskList />
                  </IconButton> */}
                  <IconButton
                    onClick={() => handleDeleteRow(index)}
                    sx={{
                      bgcolor: "#FF9696",
                      borderRadius: "8px",
                      ":hover": { bgcolor: "#FF9696" },
                    }}
                  >
                    <Delete sx={{ color: "white" }} />
                  </IconButton>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}

          {/* Empty row */}
          {/* {[...Array(3)].map((_, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell
                component="th"
                scope="row"
                align="center"
              ></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </StyledTableRow>
          ))} */}
          {/* Total rows */}
          <StyledTableRow>
            <StyledTableCell
              component="th"
              scope="row"
              align="center"
            ></StyledTableCell>
            <StyledTableCell />
            <StyledTableCell align="right">รวม</StyledTableCell>
            <StyledTableCell align="center">
              {summary.totalCost}
            </StyledTableCell>
            <StyledTableCell align="center">
              {summary.totalQuantity}
            </StyledTableCell>
            <StyledTableCell align="center">
              {summary.totalInStock}
            </StyledTableCell>
            <StyledTableCell align="center">
              {summary.totalRemain}
            </StyledTableCell>
            <StyledTableCell />
          </StyledTableRow>

          {/* Bottom rows */}
          {/* <StyledTableRow>
            <StyledTableCell align="left" colSpan={3}>
              <Stack>
                <Typography>รายการสินค้า</Typography>
                <CustomSelect dataSource={[]} onChange={() => {}} />
              </Stack>
            </StyledTableCell>
            <StyledTableCell align="right">
              <CustomInputText label="ราคาต้นทุน" />
            </StyledTableCell>
            <StyledTableCell align="right">
              <CustomInputText label="จำนวน" />
            </StyledTableCell>
            <StyledTableCell />
            <StyledTableCell />
            <StyledTableCell align="center">
              <Button

                startIcon={
                  <AddBox
                    sx={{ color: "white", width: "20px", height: "20px" }}
                  />
                }
                sx={{ bgcolor: "#79C447", py: 1, px: 2 }}
              >
                <Typography
                  sx={{ color: "white", fontSize: "18px", fontWeight: 600 }}
                >
                  เพิ่ม
                </Typography>
              </Button>
            </StyledTableCell>
          </StyledTableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default forwardRef(StockTable);
