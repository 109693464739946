import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputBase, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, TextareaAutosize, Typography } from "@mui/material";
import * as React from 'react';
import { CloudUploadOutlined, DeleteOutline, PrivacyTipOutlined, TaskOutlined } from "@mui/icons-material";
import { alpha, styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { FoodService } from "../../services/FoodService";
import Swal from 'sweetalert2';
import './style.css';
import TreeView from "../../components/Tree/Tree";



const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
        borderRadius: 19,
        position: 'relative',
        // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface Props {
    editRecord: {
        id: string
        routes_id: string
        category_id: string
        name: string
        price: string
        recommened: number
        imageUrl: string
    }
    routes: Array<any>
    categorys: Array<any>
    onClose: () => void
    onResponse: (menu: any, isUpdate: boolean) => void
}

export default function CreateOrUpdate(props: Props) {

    const treeRef = React.useRef<any>(null);
    const [foodImage, setFoodImage] = React.useState<File>()
    const [defaultTags, setDefaultTags] = React.useState<Array<any>>([]);
    const [tags, setTags] = React.useState<Array<any>>([]);
    const [foodImagePreview, setFoodImagePreview] = React.useState<string>("")
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            routeId: props.editRecord?.routes_id,
            categoryId: props.editRecord?.category_id,
            foodName: props.editRecord.name,
            foodPrice: props.editRecord.price,
            recommened: props.editRecord.recommened
        }
    });

    React.useEffect(() => {
        if (props.editRecord?.id) {
            setFoodImagePreview(props.editRecord.imageUrl)
            getFoodOptions(props.editRecord?.id);

        }
    }, [props.editRecord?.id])

    const getFoodOptions = async (menuId: string) => {
        const res = await FoodService.getFoodOptions(menuId)
        if (res?.status == 200) {
            const { menuOptions } = res.response
            setDefaultTags(menuOptions)
        }
    }


    const onChangeFile = (file: File | undefined) => {
        if (file) {
            setFoodImage(file)
            setFoodImagePreview(URL.createObjectURL(file));
        }
    }

    const onSubmit = async (data: any) => {



        const formData = new FormData();
        if (foodImage) {
            formData.append("foodImage", foodImage)
        }


        const treeData = treeRef.current?.getTreeData()
        formData.append("routeId", data.routeId)
        formData.append("categoryId", data.categoryId)
        formData.append("foodName", data.foodName)
        formData.append("foodPrice", data.foodPrice)
        formData.append("tags", JSON.stringify(treeData))
        formData.append("recommened", data.recommened == true ? "1" : "0")

        if (props.editRecord?.id) {
            return onUpdate(formData)
        }
        const res = await FoodService.saveFood(formData)
        if (res?.status == 200) {
            const { menu } = res.response;
            props.onResponse(menu, false)
            Swal.fire({
                icon: "success",
                text: res?.message
            });
            props.onClose();
        } else {
            Swal.fire({
                icon: "error",
                text: res?.message
            });
        }

    }

    const onUpdate = async (formData: FormData) => {

        const res = await FoodService.updateFood(props.editRecord.id, formData)
        if (res?.status == 200) {
            const { menu } = res.response;
            props.onResponse(menu, true)
            Swal.fire({
                icon: "success",
                text: res?.message
            });
            props.onClose();
        } else {
            Swal.fire({
                icon: "error",
                text: res?.message
            });
        }

    }


    return (
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <FormControl variant="standard">
                <Button component="label" style={{ color: 'white' }} variant="contained" startIcon={<CloudUploadOutlined />}>
                    Upload file
                    <VisuallyHiddenInput type="file" accept="image/*" onChange={file => onChangeFile(file.target.files?.[0])} />
                </Button>
                {
                    foodImagePreview ?
                        <img src={foodImagePreview} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                        :
                        null
                }

            </FormControl>

            <br></br>


            <FormControl sx={{ mt: 2, mb: 2 }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                    แผนก
                </InputLabel>

                <Controller

                    render={({ field: { onChange, value } }) => (

                        <Select
                            required
                            onChange={onChange}
                            value={value}
                            displayEmpty
                            size={'small'}
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ borderRadius: 10, color: 'black', minWidth: 150 }}
                        >

                            <MenuItem value={""}>แผนก</MenuItem>
                            {
                                props.routes.map((item, index: number) => {
                                    return (
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    )
                                })
                            }

                        </Select>


                    )}
                    control={control}
                    name={'routeId'}
                />



            </FormControl>
            <br></br>

            <FormControl sx={{ mt: 2, mb: 2 }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                    หมวดหมู่
                </InputLabel>

                <Controller

                    render={({ field: { onChange, value } }) => (

                        <Select
                            required
                            onChange={onChange}
                            value={value}
                            displayEmpty
                            size={'small'}
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ borderRadius: 10, color: 'black', minWidth: 150 }}
                        >

                            <MenuItem value={""}>หมวดหมู่</MenuItem>
                            {
                                props.categorys.map((item, index: number) => {
                                    return (
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    )
                                })
                            }

                        </Select>


                    )}
                    control={control}
                    name={'categoryId'}
                />



            </FormControl>

            <br></br>


            <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                    ชื่ออาหาร
                </InputLabel>
                <BootstrapInput
                    {...register('foodName', { required: true })}
                    defaultValue="" id="bootstrap-input" />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                    ราคา
                </InputLabel>
                <BootstrapInput
                    {...register('foodPrice', { required: true })}
                    defaultValue="" id="bootstrap-input" />
            </FormControl>


            {
                React.useMemo(() => {
                    return (
                        <TreeView
                            ref={treeRef}
                            data={{
                                id: 0,
                                type: "0",
                                text: 'เพิ่มตัวเลือก',
                                children: defaultTags
                            }}
                        ></TreeView>
                    )
                }, [defaultTags])
            }





            <FormGroup>
                <Controller
                    control={control}
                    name={`recommened`}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel control={<Checkbox checked={value ? true : false} onChange={onChange}></Checkbox>} label="เมนูแนะนำ" />
                    )}
                />
            </FormGroup>

            <br></br>

            <br></br>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Button
                        onClick={() => props.onClose()}
                        fullWidth
                        size={"large"}
                        style={{
                            border: "1px solid #ff6801", color: "#ff6801",
                            borderRadius: 25,
                            padding: '10px 35px 10px 35px'
                        }} >ยกเลิก</Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Button type="submit" fullWidth size={"large"} style={{
                        backgroundColor: "#ff6801", color: "white",
                        borderRadius: 25,
                        padding: '10px 35px 10px 35px'
                    }} >{props.editRecord?.id ? "แก้ไข" : "เพิ่ม"}</Button>
                </Grid>
            </Grid>
        </form >
    )
}