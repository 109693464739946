import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import CustomInputText from "../../../components/InputText/CustomInputText";
import StockTable from "../../../components/StockManagement/AddStock/StockTable";
import React from "react";
import { StockService } from "../../../services/StockService";
import { CloudUploadOutlined, Download, PlayArrow, Upload } from "@mui/icons-material";
import Swal from 'sweetalert2';
import { alpha, styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function AddStock() {

  const stockRef = React.useRef<any>(null)
  const [stocks, setStocks] = React.useState<any[]>([])

  React.useEffect(() => {
    getStocks();
  }, [])

  const getStocks = async () => {
    const res = await StockService.getStocks();
    if (res?.status == 200) {
      const { stocks } = res.response;
      setStocks(stocks)
    }
  }

  const saveStock = async () => {
    const results = stockRef.current?.getResults()
    const res = await StockService.saveStock({
      datas: results
    })
    if (res?.status == 200) {
      Swal.fire({
        icon: "success",
        text: res?.message
      });
    } else {
      Swal.fire({
        icon: "error",
        text: res?.message
      });
    }

  }

  const downloadStock = async () => {
    try {
      const response = await StockService.downloadStock();

      // Create a blob URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'output.xlsx'); // Set the filename
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }

  }

  const uploadStock = async (e: any) => {
    try {
      const formData = new FormData();
      formData.append('stockExcel', e.target.files?.[0])
      const res = await StockService.uploadStock(formData);

      if (res?.status == 200) {
        Swal.fire({
          icon: "success",
          text: res?.message
        });
      } else {
        Swal.fire({
          icon: "error",
          text: res?.message
        });
      }
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
    // Reset the file input
   
      e.target.value = null;



  }

  const onChangeFile = (e : any) => {

      uploadStock(e)   
  }


  return (
    <>
      <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 600,
            fontSize: { xs: "18px", md: "24px" },
          }}
        >
          รับสินค้าเข้าสต๊อก (เพิ่ม-ลด)
        </Typography>
      </Grid>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2} display={'flex'} justifyContent={'flex-end'} >
          <Grid item >
            <Button
              onClick={() => downloadStock()}
              variant="contained"
              color="warning"
              sx={{ color: "white" }}
              startIcon={<Download />}
              endIcon={<PlayArrow sx={{ transform: "rotate(90deg)" }} />}
            >
              ดาวโหลดข้อมูลสินค้า
            </Button>
          </Grid>
          <Grid item>

            <Button component="label"
              sx={{ bgcolor: (theme) => theme.palette.grey[300], color: "black" }}
              variant="contained" startIcon={<CloudUploadOutlined />}>
              อัพโหลดข้อมูล
              <VisuallyHiddenInput type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={e => onChangeFile(e)} />
            </Button>
            {/* <Button
              onClick={() => uploadStock()}
              variant="contained"
              sx={{ bgcolor: (theme) => theme.palette.grey[300], color: "black" }}
              startIcon={<Upload />}
            >
              อัพโหลดข้อมูล
            </Button> */}
          </Grid>
          <Grid item >
            <Button
              onClick={() => saveStock()}
              variant="contained" color="success" sx={{ color: "white" }}>
              ยืนยัน อัพเดทสต๊อก
            </Button>
          </Grid>

        </Grid>


        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* First row */}
          <Grid item sm={12} md={6}>
            <CustomInputText label={"วันที่"} />
          </Grid>
          <Grid item sm={12} md={6}>
            <CustomInputText label={"บริษัทคู่ค้า"} />
          </Grid>

          {/* Second row */}
          <Grid item sm={12} md={6}>
            <Typography>ผู้จัดทำรายการ : admin</Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <CustomInputText label={"เลขที่เอกสารอ้างอิง "} />
          </Grid>
        </Grid>
        <Box sx={{ mt: 4 }}>
          <StockTable
            ref={stockRef}
            stocks={stocks}
          />

          <Typography sx={{ fontSize: "15px", fontWeight: 500, mt: 2 }}>
            หมายเหตุ หากต้องการลดจำนวนสินค้าในคลัง ให้ใส่เครื่องหมาย (ลบ) -
            หน้าตัวแลขที่ต้องการเช่น -2
          </Typography>
        </Box>
      </Paper>
    </>
  );
}
