import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Menu from "../../models/menu";

interface SelectLabelsProp {
  dataSource: Menu[];
  onChange: (value: number) => void;
}

export default function CustomSelect(props: SelectLabelsProp) {
  const { dataSource, onChange } = props;
  const [inputValue, setInputValue] = React.useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    onChange(parseInt(event.target.value));
    setInputValue(parseInt(event.target.value));
  };

  return (
    <Select
      value={`${inputValue}`}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      sx={{ minWidth: "150px" }}
    >
      {dataSource.map((data) => (
        <MenuItem key={data.id} value={data.id}>
          {data.name}
        </MenuItem>
      ))}
    </Select>
  );
}
