import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link } from 'react-router-dom';
import { Box, Collapse, List, ListItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// interface MenuProps {
//     title: string
//     to?: string
//     open?: boolean
//     children?: MenuProps[]
//     iconImgFullPath?: string
// }

export default function Item(props: any) {

    const [open, setOpen] = React.useState<boolean>(false)

    return (
        <div>
            <ListItem button {...props?.to ? { component: Link, to: props.to } : {}} onClick={() => {
                props.to != undefined && props.toggleDrawer();
                setOpen(!open)
            }}>
                {/* <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon> */}
                {
                    props.iconImgFullPath ?
                        <img src={`${props.iconImgFullPath}`} />
                        :
                        null
                }

                <ListItemText style={{ paddingLeft: 10}} primary={props.title} />
                {
                    props.children ?
                        open ? <ExpandLess /> : <ExpandMore />
                        :
                        null
                }
            </ListItem>



            {
                props.children ?
                    <>
                        <Collapse
                            in={open}
                            timeout='auto'
                            unmountOnExit
                        >
                            <List component='div' disablePadding>
                                {props?.children?.map((child:any, subIndex:any) => {
                                    return (
                                        <ListItem button component={Link} to={child.to || ''} key={`submenu_${subIndex}`} onClick={() => {
                                            props.toggleDrawer();
                                        }}>
                                            <ListItemIcon>
                                                {/* <DashboardIcon /> */}
                                            </ListItemIcon>
                                            <ListItemText primary={child.title} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Collapse>
                    </>
                    :
                    null
            }
        </div>
    );
}