import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, TextField, Typography, Paper, IconButton, Checkbox, Box, Radio, FormGroup, FormControlLabel, RadioGroup } from '@mui/material';
import { Delete } from '@mui/icons-material';

interface Node {
  id: number;
  text: string;
  type: string;
  children?: Node[];
}

interface TreeNodeProps {
  node: Node;
  level: number; // Add level prop
  onNodeChange: (updatedNode: Node) => void;
  onAddChild: (parentId: number) => void;
  onRemoveNode: (nodeId: number) => void;
}

const TreeNode: React.FC<TreeNodeProps> = ({ node, level, onNodeChange, onAddChild, onRemoveNode }) => {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(node.text);
  const [type, setType] = useState(node.type);

  React.useMemo(() => {
    setText(node.text)
  }, [node.text])

  React.useMemo(() => {
    setType(node.type)
  }, [node.type])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value);
    onNodeChange({ ...node, type: e.target.value });
  };

  const handleNodeChange = () => {
    onNodeChange({ ...node, text });
    setEditing(false);
  };

  const handleAddChild = () => {
    onAddChild(node.id);
  };

  const handleRemoveNode = () => {
    onRemoveNode(node.id);
  };

  return (
    <Paper elevation={1} style={{ padding: '10px', marginBottom: '5px' }}>
      {editing && node.id != 0 ? (
        <TextField
          value={text}
          onChange={handleInputChange}
          onBlur={handleNodeChange}
          autoFocus
          fullWidth
        />
      ) : (
        <>

          {
            level <= 1 ?
              <Button onClick={handleAddChild} variant="outlined" size="small" style={{ marginRight: '5px' }}>
                เพิ่ม
              </Button>
              :
              null
          }
          <span style={{ minHeight: 30, color: level >= 1 && !node.text ? '#ccc' : 'black' }} onClick={() => setEditing(true)}>


            {
              !node.text && level == 1 ?
                'กรอกที่นี้ (Ex. ระดับความเผ็ด)'
                :
                null
            }
            {
              !node.text && level == 2 ?
                'กรอกที่นี้ (Ex. เผ็ดมาก)'
                :
                null
            }
            {
              node.text ?
                node.text :
                null
            }

          </span>


          {
            node.id != 0 ?
              <IconButton onClick={handleRemoveNode} size="small">
                <Delete />
              </IconButton>
              :
              null
          }

          {
            level == 1 ?
              <FormGroup >
                <RadioGroup
                  onChange={handleRadioChange}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={`${type}`}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value="1" control={<Radio />} label="ตัวเลือกเดียว" />
                  <FormControlLabel value="2" control={<Radio />} label="หลายตัวเลือก" />
                </RadioGroup>

              </FormGroup>
              :
              null
          }


        </>

      )}





      {node.children && (
        <div style={{ paddingLeft: '20px' }}>
          {node.children.map((child, index) => (
            <TreeNode
              key={index}
              node={child}
              level={level + 1} // Increase level by 1 for children
              onNodeChange={onNodeChange}
              onAddChild={onAddChild}
              onRemoveNode={onRemoveNode}
            />
          ))}
        </div>
      )}
    </Paper>
  );
};

interface TreeViewProps {
  data: Node;
}

const TreeView = ({ data }: TreeViewProps, ref: any) => {


  const [treeData, setTreeData] = useState<Node>(data);
  const [results, setResults] = useState<Array<any>>([]);

  React.useEffect(() => {

    setTreeData(data)
    const arr = convertData(data)
    setResults(arr)

  }, [data])

  useImperativeHandle(ref, () => ({
    getTreeData: () => {
      return results;
    }
  }))

  const convertData = (raw: any) => {
    const arr: Array<any> = [];
    raw.children?.filter((item: any) => {
      arr.push({
        name: item.text,
        type: item.type,
        option: item.children?.map((item1: any) => {
          return {
            label: item1.text
          }
        })
      })
    })
    return arr
  }


  const handleNodeChange = (updatedNode: Node) => {
    const updatedTree = updateNode(treeData, updatedNode);
    setTreeData(updatedTree);

    //re-format
    const arr = convertData(updatedTree)
    setResults(arr)



  };

  const handleAddChild = (parentId: number) => {
    const newNode: Node = {
      id: Date.now(),
      type: "1",
      text: '',
    };
    const updatedTree = addChild(treeData, parentId, newNode);
    setTreeData(updatedTree);

    //re-format
    const arr = convertData(updatedTree)
    setResults(arr)
  };

  const handleRemoveNode = (nodeId: number) => {
    const updatedTree = removeNode(treeData, nodeId);
    setTreeData(updatedTree);

    //re-format
    const arr = convertData(updatedTree)
    setResults(arr)
  };

  const updateNode = (tree: Node, updatedNode: Node): Node => {
    if (tree.id === updatedNode.id) {
      return { ...tree, ...updatedNode };
    }
    if (tree.children) {
      return {
        ...tree,
        children: tree.children.map((child) => updateNode(child, updatedNode)),
      };
    }
    return tree;
  };

  const addChild = (tree: Node, parentId: number, newNode: Node): Node => {
    if (tree.id === parentId) {
      return { ...tree, children: [...(tree.children || []), newNode] };
    }
    if (tree.children) {
      return {
        ...tree,
        children: tree.children.map((child) => addChild(child, parentId, newNode)),
      };
    }
    return tree;
  };

  const removeNode = (tree: Node, nodeId: number): Node | null | any => {
    if (tree.id === nodeId) {
      return null;
    }
    if (tree.children) {
      return {
        ...tree,
        children: tree.children.filter((child) => child.id !== nodeId).map((child) => removeNode(child, nodeId)),
      };
    }
    return tree;
  };

  return (
    <div>
      <TreeNode
        node={treeData}
        level={0}
        onNodeChange={handleNodeChange}
        onAddChild={handleAddChild}
        onRemoveNode={handleRemoveNode}
      />
    </div>
  );
};

// Usage
// const treeData: Node = {
//   id: 0,
//   text: 'เพิ่มตัวเลือก',
//   children: [
//     // {
//     //   id: 2,
//     //   text: 'Child 1',
//     // },
//     // {
//     //   id: 3,
//     //   text: 'Child 2',
//     // },
//   ],
// };



export default forwardRef(TreeView);