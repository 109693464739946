import React, { ChangeEvent, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { MdOutlineFileDownload } from "react-icons/md";
import axios from "axios";
import InventoriesModel from "../../models/report";
import Menu from "../../models/menu";
import CustomSelect from "../../components/Select/CustomSelect";

export default function Inventory() {
  const [listInventories, setListInventories] = useState<InventoriesModel[]>();
  const [listMenus, setListMenus] = useState<Menu[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<number>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getListMenus = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories`)
      .then((response) => {
        var list = response.data.response?.categorys?.map((data: any): Menu => {
          return {
            id: data.id,
            name: data.name,
          };
        });
        setListMenus(list ?? []);
      });
  };

  const getListInventories = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/reports/inventories?type=${
          selectedMenu ? selectedMenu : ""
        }`
      )
      .then((response) => {
        setListInventories(response.data.response);
      });
  };

  useEffect(() => {
    getListInventories();
    getListMenus();
  }, []);

  return (
    <Grid container sx={{ flexGrow: 1, width: "100%" }}>
      <Grid
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        md={12}
        sx={{
          bgcolor: "#FFB802",
          width: "100%",
          mb: 2,
          mt: 0,
          ml: { xs: 0, md: 0 },
          p: 3,
        }}
      >
        <Typography
          sx={{ color: "#FFFFFF", fontSize: { xs: "18px", md: "24px" } }}
        >
          รายงานสินค้าคงเหลือ
        </Typography>
      </Grid>

      <Paper
        sx={{
          width: { xs: "95%", md: "98%" },
          p: 3,
          ml: { xs: 1, md: 2 },
          mt: -1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography>หมวด</Typography>
            <CustomSelect
              dataSource={[{ id: 0, name: "ทั้งหมด" }, ...listMenus]}
              onChange={(value) => setSelectedMenu(value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            container
            direction="row"
            justifyContent="flex-start"
            sx={{ mt: 3, ml: { xs: 0, sm: 0, md: -25, lg: -55 } }}
          >
            <Button
              size="large"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#FF9B28",
                color: "#FFFFFF",
                padding: "9px 10px 9px 10px",
              }}
              sx={{
                width: { xs: "100%", md: "151px" },
                height: "42px",
                fontSize: "15px",
              }}
              onClick={() => getListInventories()}
            >
              ค้นหา
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: { xs: 0, sm: 10, md: -12, lg: -12 }, ml: { xs: 0, sm: -29, md: 30, lg: 65 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#38DF67",
                color: "#FFFFFF",
              }}
              sx={{
                width: { xs: "100%", md: "219px" },
                height: "43px",
                fontSize: "15px",
                whiteSpace: "nowrap",
                overflowWrap: "break-word",
              }}
            >
              <IconButton color="inherit" size="medium">
                <MdOutlineFileDownload />
              </IconButton>
              ดาวโหลดข้อมูลไฟล์ Excel
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: { xs: 0, sm: -7.5, md: -12, lg: -12 }, ml: { xs: 0, sm: 32, md: 50, lg: 120 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#4DBCFA",
                color: "#FFFFFF",
              }}
              sx={{
                width: { xs: "100%", md: "92px" },
                height: "43px",
                fontSize: "15px",
              }}
            >
              <IconButton color="inherit" size="medium">
                <PrintIcon />
              </IconButton>
              พิมพ์
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        item
        xs={12}
        md={12}
        sx={{ mt: 2, overflowX: "auto", marginLeft: { xs: 1, sm: 2, md: 2 } }}
      >
        <Table
          sx={{
            borderRadius: "8px",
            width: { xs: "100%", sm: "100%", md: "99%" },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                ลำดับ
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                รหัส/เลขบาร์โค้ด
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                รายการขาย
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                คงเหลือ
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                หน่วย
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                มูลค่าคงเหลือ
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={isMobile ? { flexDirection: "column" } : {}}>
            {listInventories && listInventories.length > 0 ? (
              <>
                {listInventories.map((inventory) => (
                  <TableRow
                    key={inventory.id}
                    sx={isMobile ? { flexDirection: "column" } : {}}
                  >
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: "8px",
                        borderBottom: "1px solid #000",
                        borderLeft: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      {inventory.id}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: "8px",
                        borderBottom: "1px solid #000",
                        borderLeft: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      {inventory.barcode}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: "8px",
                        borderBottom: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      {inventory.name}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: "8px",
                        borderBottom: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      {inventory.quantity}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: "8px",
                        borderBottom: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      {inventory.unit}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        padding: "8px",
                        borderBottom: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      {inventory.price * inventory.quantity}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <Typography>ยังไม่มีในสต๊อก</Typography>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
