import React, { ChangeEvent, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Box,
  Badge,
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import { SiAddthis } from "react-icons/si";


export default function Promotion() {
  const [accumulatedPoints, setAccumulatedPoints] = useState<number>(20);

  const handlePointsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccumulatedPoints(parseInt(event.target.value, 10) || 0);
  };
  return (
    <Grid container sx={{ flexGrow: 1, width: '100%' }}>
      <Grid item direction="row" justifyContent="flex-start" alignItems="center" xs={12} md={12} sx={{
        bgcolor: "#FFB802",
        width: "100%",
        mb: 2,
        mt: 0,
        ml: { xs: 0, md: 0 },
        p: 3,
      }}>
        <Typography sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}>
          ตั้งค่าการเก็บ สะสมแต้ม
        </Typography>
      </Grid>

      <Paper sx={{ width: { xs: '95%', md: '98%' }, p: 8, ml: { xs: 1, md: 2 }, mt: -1 }}>
        <Grid item xs={12} sm={6} md={6} lg={6} container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mt: { xs: -5, sm: -5, md: -5, lg: -5 }, ml: { xs: -7, sm: -6, md: -4, lg: -4 } }}>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: '500',
            lineHeight: '20px',
            whiteSpace: 'nowrap',
            overflowWrap: 'break-word',
          }}>
            ให้คะแนนสะสมตามเมนูรายการสินค้าและบริการ
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          sx={{ mt: { xs: 2, md: 4 }, ml: { xs: -7, md: -4 } }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "20px",
              whiteSpace: "nowrap",
              overflowWrap: "break-word",
            }}
          >
            ให้คะแนนสะสม ตามยอดซื้อ
          </Typography>
        </Grid>

        <Box sx={{ ml: { xs: -4, md: 25 }, mt: { xs: 2, md: -4 } }}>
          {/* แสดงตัวเลขคะแนนสะสม */}
          <Box
            sx={{
              border: "1px solid #E7E8F2",
              borderRadius: "8px 8px 8px 8px",
              gap: "10px",
              width: { xs: "53px", md: "53px" },
              height: "42px",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#000000",
                }}
              >
                {accumulatedPoints}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Grid item xs={12} sm={6} md={6} lg={6} container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mt: { xs: 3, sm: 3, md: -4, lg: -4 }, ml: { xs: -7, sm: -6, md: 35, lg: 35 } }}>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: '500',
            lineHeight: '20px',
            flexWrap: { xs: 'wrap', sm: 'nowrap' },
            overflowWrap: 'break-word',
          }}>
            บาท ได้ 1 คะแนน เช่น 40 บาท ได้ 1 คะแนน 80 บาทได้ 2 คะแนน
          </Typography>
        </Grid>

        <Grid container width='100%' flexDirection='column'>
          <Grid>
            <Typography sx={{
              fontSize: '18px',
              fontWeight: '500',
              lineHeight: '20px',
            }}>
              ข้อความแสดง
            </Typography>
          </Grid>

          <Grid sx={{width:{xs:'100% ',md:'50%'}}}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              placeholder=""
            />
          </Grid>
          <Grid>
            <Typography sx={{
              fontSize: '18px',
              fontWeight: '500',
              lineHeight: '20px',
            }}>
              คะแนนสะสมของลูกค้า
            </Typography>
          </Grid>

          <Grid container sx={{width:{xs:'100% ',md:'50%'}}} justifyContent='space-between'>
            <Grid >
              <Button
                size="small"
                color="inherit"
                style={{ borderRadius: 4, background: '#4DBCFA', color: '#FFFFFF' }}
                sx={{
                  width: { xs: '100%', sm: '92px', md: '92px', lg: '92px' },
                  height: '43px',
                  fontSize: '15px',
                  borderRadius: '4px',
                }}
              >
                บันทึก
              </Button>
            </Grid>

            <Grid>
              <Button
                size="small"
                color="inherit"
                style={{ borderRadius: 4, background: '#38DF67', color: '#FFFFFF' }}
                sx={{
                  width: { xs: '100%', md: '92px' },
                  height: '43px',
                  fontSize: '15px',
                  borderRadius: '4px',
                }}
              >
                แก้ไข
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Paper>

      <Grid item direction="row" justifyContent="flex-start" alignItems="center" xs={12} md={12} sx={{
        bgcolor: "#FFB802",
        width: "100%",
        mb: 2,
        mt: 1,
        ml: { xs: 0, md: 0 },
        p: 3,
      }}>
        <Typography sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}>
          โปรโมชั่น สะสมแต้ม
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2, ml: { xs: 1, sm: 2, md: 4, lg: 4 } }}>
        <Table sx={{ borderRadius: '8px', width: { xs: '100%', sm: '190%', md: '190%', lg: '100%' } }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', borderRadius: '8px 0px 0px 0px', border: '1px solid #BBBBBB' }}>ลำดับ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', border: '1px solid #BBBBBB' }}>คะแนน</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', border: '1px solid #BBBBBB' }}>แลกส่วนลด (บาท)</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', borderRadius: '0px 8px 0px 0px', border: '1px solid #BBBBBB' }}>หมายเหตุ</TableCell>
            </TableRow>
          </TableHead>
        </Table>

        <Table sx={{ borderRadius: '8px', width: { xs: '100%', sm: '190%', md: '190%', lg: '100%' }, height: { xs: '100%', sm: '50%', md: '150%', lg: '100%' } }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', borderLeft: '1px solid' }}></TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', }}></TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', }}></TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', borderRight: '1px solid' }}></TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>


      <Grid item xs={12} sm={6} md={6} lg={6} container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mt: { xs: 15, sm: 30, md: 20, lg: 10 }, ml: { xs: 1, sm: -33, md: 5, lg: 5 } }}>
        <Button
          size="large"
          color="inherit"
          style={{ borderRadius: 4, background: '#79C447', color: '#FFFFFF' }}
          sx={{
            width: { xs: '100%', md: '91px' },
            height: '40px',
            fontSize: '15px',
          }}
        >
          <IconButton color="inherit" size="medium">
            <SiAddthis />
          </IconButton>
          เพิ่ม
        </Button>
      </Grid>

    </Grid>
  );
}