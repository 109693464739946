import React, { ChangeEvent, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { MdOutlineFileDownload } from "react-icons/md";
import axios from "axios";

export default function Expense() {
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [listExpenses, setListExpenses] = useState([] as any);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(event.target.value);
  };

  const getListExpenses = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/reports/expenses`)
      .then((response) => {
        setListExpenses(response.data.response);
      });
  };

  useEffect(() => {
    getListExpenses();
  }, []);

  return (
    <Grid container sx={{ flexGrow: 1, width: "100%" }}>
      <Grid
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        md={12}
        sx={{
          bgcolor: "#FFB802",
          width: "100%",
          mb: 2,
          mt: 0,
          ml: { xs: 0, md: 0 },
          p: 3,
        }}
      >
        <Typography
          sx={{ color: "#FFFFFF", fontSize: { xs: "18px", md: "24px" } }}
        >
          รายงานรายจ่าย
        </Typography>
      </Grid>

      <Paper
        sx={{
          width: { xs: "95%", md: "98%" },
          p: 3,
          ml: { xs: 1, md: 2 },
          mt: -1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}
            sx={{ mt: { xs: 0, md: 0 }, ml: { xs: 0, md: 0 } }}
          >
            <Typography>หัวข้อ</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "291px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 0, sm: 0, md: 0, lg: 0 },
              }}
              variant="outlined"
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}
            sx={{ mt: { xs: 0, sm: 0, md: 0, lg: -11 }, ml: { xs: 0, sm: 0, md: 0, lg: 45 } }}
          >
            <Typography>เลขที่ใบเสร็จ</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "291px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              variant="outlined"
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}
            sx={{ mt: { xs: 0, md: 2 }, ml: { xs: 0, md: 0 } }}
          >
            <Typography>บริษัทคู่ค้า</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "291px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 0, sm: 0, md: 0, lg: 2 },
              }}
              variant="outlined"
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}
            sx={{ mt: { xs: 0, sm: 0, md: 2, lg: -13 }, ml: { xs: 0, sm: 0, md: 0, lg: 45 } }}
          >
            <Typography>วันที่</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "291px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              variant="outlined"
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            container
            direction="row"
            justifyContent="flex-start"
            sx={{ mt: { xs: 0, sm: 0, md: 0, lg: -7 }, ml: { xs: 0, sm: 0, md: 0, lg: 90 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#FF9B28",
                color: "#FFFFFF",
                padding: "9px 10px 9px 10px",
              }}
              sx={{
                width: { xs: "100%", md: "151px" },
                height: "43px",
                fontSize: "15px",
              }}
            >
              ค้นหา
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: { xs: 0, sm: 0, md: 0, lg: -28 }, ml: { xs: 0, sm: 0, md: -16, lg: 65 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#38DF67",
                color: "#FFFFFF",
              }}
              sx={{
                width: { xs: "100%", md: "219px" },
                height: "43px",
                fontSize: "15px",
                whiteSpace: "nowrap",
                overflowWrap: "break-word",
              }}
            >
              <IconButton color="inherit" size="medium">
                <MdOutlineFileDownload />
              </IconButton>
              ดาวโหลดข้อมูลไฟล์ Excel
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: { xs: 0, sm: 0, md: -7, lg: -28 }, ml: { xs: 0, sm: 0, md: 45, lg: 120 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#4DBCFA",
                color: "#FFFFFF",
              }}
              sx={{
                width: { xs: "100%", md: "92px" },
                height: "43px",
                fontSize: "15px",
              }}
            >
              <IconButton color="inherit" size="medium">
                <PrintIcon />
              </IconButton>
              พิมพ์
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        item
        xs={12}
        md={12}
        sx={{ mt: 2, overflowX: "auto", marginLeft: { xs: 1, sm: 2, md: 2 } }}
      >
        <Table
          sx={{
            borderRadius: "8px",
            width: { xs: "100%", sm: "100%", md: "99%" },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                วันที่
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >หัวข้อ</TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                เลขที่ใบเสร็จ
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                บริษัทคู่ค้า
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                รายการ
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderBottom: "1px solid #000",
                }}
              >
                ยอดเงินสุทธิ
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={isMobile ? { flexDirection: "column" } : {}}>
            {listExpenses?.map((row: any, index: number) => (
              <TableRow
                key={index}
                sx={isMobile ? { flexDirection: "column" } : {}}
              >
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "8px",
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                    borderRight: "1px solid #000",
                  }}
                >
                  {row.date_}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "8px",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                  }}
                >
                  {row.name_title}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "8px",
                    overflow: "hidden",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                  }}
                >
                  {row.no_bill}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "8px",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                  }}
                >
                  {row.partner}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "8px",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                  }}
                >
                  {row.list_item}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "8px",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                  }}
                >
                  {row.total_all} บาท
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell
                colSpan={3}
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  padding: 8,
                  borderBottom: "1px solid #000",
                  borderLeft: "1px solid #000",
                }}
              >
                ยอดรวม
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  padding: "8px",
                  borderBottom: "1px solid #000",
                  borderLeft: "1px solid #000",
                }}
              ></TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  padding: "8px",
                  borderBottom: "1px solid #000",
                  borderLeft: "1px solid #000",
                }}
              ></TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  padding: "8px",
                  borderBottom: "1px solid #000",
                  borderRight: "1px solid #000",
                  borderLeft: "1px solid #000",
                }}
              >
                {listExpenses.reduce((accumulator :any, currentValue :any) => {
                  return accumulator + currentValue.total_all;
                }, 0)} บาท
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
