const _accessToken = 'accessToken';

class LocalStorageService {

    private static instance: LocalStorageService;

    static get(): LocalStorageService {
        if (!LocalStorageService.instance) {
            LocalStorageService.instance = new LocalStorageService();
        }
        return LocalStorageService.instance;
    }

    setAccessToken(accessToken: string) {
        localStorage.setItem(_accessToken, accessToken)
    }


    getAccessToken(): string {
        return `${localStorage.getItem(_accessToken)}`
    }



}


const localStorageService = LocalStorageService.get();

export { localStorageService as LocalStorageService }