import { Box, Button, Card, CardMedia, Grid, InputAdornment, TextField, Typography, InputLabel, FormControl, OutlinedInput, Modal, List, ListItem, ListItemAvatar, ListItemText, Divider, InputBase, Select, MenuItem, Paper, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import face_Api from './faceApi.json'
import face_Menu from './faceApiSelectMenu.json'
import moneyIcon from '../../assets/money.png';
import qrCode from '../../assets/qr-scan.png';
import BackspaceIcon from '@mui/icons-material/Backspace';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import EmailIcon from '@mui/icons-material/Email';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Link } from 'react-router-dom';
import './test.css'
import { alpha, styled } from "@mui/material/styles";
import { OrderService } from '../../services/OrderService';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { GridSearchIcon } from '@mui/x-data-grid';
import axios from 'axios';
import Foodca from './Foodca';

interface ProductProps {
    productName: string
    productPrice: string
    productImg: string
}

interface SelectCategoryMenuProps {
    categoryId: string
    categoryName: string
}


const ListOfProductOrders = ({ allProductOrders }: { allProductOrders: any[] }) => {
    return (
        <>
            <Typography
                mt={4}
                pb={1}
                variant={"h6"}
                style={{ fontWeight: "bold" }}
                sx={{ mt: { sx: 0, sm: 0, md: 590, lg: 7 } }}
            >
                รายการอาหาร
            </Typography>
            <List sx={{ width: "100%", overflow: 'auto', height: allProductOrders.length ? 400 : 'auto' }}>
                {allProductOrders.map((item: any, index: number) => {
                    return (
                        <>
                            <ListItem alignItems="flex-start" key={index}>
                                <ListItemAvatar style={{ paddingRight: 10 }}>
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 80,
                                            width: 80,
                                            borderRadius: 3,
                                        }}
                                        src={item.imageUrl}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.product_name}
                                    primaryTypographyProps={{
                                        fontWeight: 'bold'
                                    }}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: "inline" }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                ราคา
                                            </Typography>
                                            <div
                                                style={{ color: "orange", fontWeight: "bold", fontSize: 15 }}
                                            >
                                                ฿{item.price}
                                            </div>
                                        </React.Fragment>
                                    }
                                />

                            </ListItem>
                            <Divider></Divider>
                        </>
                    );
                })}
            </List>
        </>
    )
}

export default function FoodMenu() {


    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    
    const styleBTN = {
        cursor: 'pointer',
        bgcolor: '#7f7f7f1f',
        p: { sm: 0, xs: 0, md: 1.7 },
        borderRadius: '40%',
        m: '3px 6px'
    }

   

    const [selectCategoryMenu, setSelectCategoryMenu] = useState<SelectCategoryMenuProps>({ categoryId: '', categoryName: '' })
    const [tables, setTables] = useState([]);
    const [tableSelected, setTableSelected] = useState<string>("");
    const [allFoods, setAllFoods] = useState([]);
    const [allProductByCategorys, setAllProductByCategorys] = useState([]);
    const [allProductOrders, setAllProductOrders] = useState([]);
    const [totalPrice, setTotalPrice] = useState<string>("0");
    const [allCategorys, setAllCategorys] = useState([]);
    const [open, setOpen] = useState<boolean>(false);
    const [payment, setPayment] = useState<boolean>(false);
    const [getAmount, setGetAmount] = React.useState<string>("")
    const handleOpen = () => setOpen(!open);
    const [discountAmount, setDiscountAmount] = useState<any>(0);
    const [discountType, setDiscountType] = useState<string>('percent');
    const [totalAmount, setTotalAmount] = useState<any>(0);
    const [changeAmount, setChangeAmount] = useState<string>("0");
    const [grandTotalPrice, setGrandTotalPrice] = useState<string>("0");

    React.useEffect(() => {
        calculate();
    }, [getAmount, discountAmount])

    React.useEffect(() => {
        getOrderTables();
        getFoods();

    }, []);

    React.useEffect(() => {
        if (tableSelected) {
            getOrderProducts(tableSelected)
        }
    }, [tableSelected])

    React.useEffect(() => {
        if (selectCategoryMenu.categoryId) {
            setAllProductByCategorys(allFoods.filter((item: any) => item.category_id == selectCategoryMenu.categoryId))
        } else {
            setAllProductByCategorys(allFoods)
        }
    }, [selectCategoryMenu.categoryId])

    const getOrderTables = async () => {
        const res = await OrderService.getOrderTables()
        if (res?.status == 200) {
            const { allTables } = res.response
            setTables(allTables)
        }
    }

    const setDiscount = (amount:number, type:string) => {
        if(type == 'percent' || type == 'baht')
        {
            setDiscountType(type)
        }
        else
        {
            return;
        }

        setDiscountAmount(amount);
    }

    const getFoods = async () => {
        const res = await OrderService.getFoods()
        if (res?.status == 200) {
            const { menus, categorys } = res.response
            setAllFoods(menus)
            setAllProductByCategorys(menus)
            setAllCategorys(categorys)
        }
    }

    const onSearchMenu = (keyword: string) => {
        if (!keyword.trim() && !selectCategoryMenu.categoryId) {
            setAllProductByCategorys(allFoods)
            return;
        }

        setAllProductByCategorys(allFoods.filter((item: any) => {
            const regex = new RegExp(keyword, "gi");
            const matches = item.product_name.match(regex);
            console.log("matches", matches)
            if (!selectCategoryMenu.categoryId && matches) {
                return item;
            } else if (item.category_id == selectCategoryMenu.categoryId && matches) {
                return item;
            }
        }))
    }

    const getOrderProducts = async (tableId: string) => {
        const res = await OrderService.getOrderProducts(tableId)
        if (res?.status == 200) {
            const { allOrders, allCategorys, totalPrice } = res.response
            setAllProductOrders(allOrders)
            setTotalPrice(totalPrice)
        }
    }


    const printReceipt = async () => {
        const res = await OrderService.printOrderReceipt(tableSelected)
        if (res?.status == 200) {
            const { receiptUrl } = res.response
            window.location.href = receiptUrl
        }

    }

    const savePaymentOrder = async () => {
        const res = await OrderService.savePaymentOrder({
            tableId: tableSelected
        })
        const list = allProductOrders.map(obj => {
            return Object.fromEntries(
                Object.entries(obj).filter(([key, value]) => key === 'amount' || key === 'product_name' || key === 'imageUrl' || key === 'price')
            )
        })
        await axios.post(`${process.env.REACT_APP_API_URL}/log/addlog`, {
            list, totalPrice, getAmount, discountAmount
        }).then(res => console.log(res.data.message))

        if (res?.status == 200) {

            Swal.fire({
                icon: "success",
                text: res?.message
            });
            window.location.reload()
        } else {
            Swal.fire({
                icon: "error",
                text: res?.message
            });
        }
    }
    console.log(allProductOrders)
    // console.log(allProductOrders.map(obj => {
    //     return Object.fromEntries(
    //         Object.entries(obj).filter(([key, value]) => key === 'amount' || key === 'product_name')
    //     )
    // }))
    const handlePayment = () => {
        setPayment(!payment)
        setOpen(!open)
    }

    const addAmount = (input: string) => {
        if (input === 'C') {
            setGetAmount("0");
            setDiscountAmount(0);
            setChangeAmount("0");
        } else if (input === 'CC') {
            const result = getAmount.substring(0, getAmount.length - 1);
            setGetAmount(result);
        } else if (!isNaN(parseFloat(input))) {
            setGetAmount((prevAmount) => prevAmount + input);
        }

    };

    const calculate = () => {
        const getTotalAmount = parseFloat(totalPrice);
        const getTotalReceiveAmount = parseFloat(getAmount);
        const getDiscountAmount = parseFloat(discountAmount);
        const getDiscountType = discountType;

        let temp_totalPrice = 0;
        let temp_discountBaht = 0;

        if(getDiscountAmount > 0)
        {
            if(getDiscountType == 'percent')
            {
                temp_discountBaht = (getTotalAmount * getDiscountAmount) / 100;
            }
            else if(getDiscountType == 'baht')
            {
                temp_discountBaht = getTotalAmount - getDiscountAmount;
            }
        }

        const totalAmountRemoveDiscount = getTotalAmount - temp_discountBaht;
        setTotalAmount(totalAmountRemoveDiscount);
        setGrandTotalPrice(parseFloat((parseFloat(getAmount || '0') - totalAmountRemoveDiscount).toString()).toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }));

        // let grandTotalPrice = (parseFloat(getAmount || '0') - parseFloat(totalPrice))
        // let _discountAmount = parseFloat(discountAmount);
        // if (_discountAmount) {
        //     grandTotalPrice = grandTotalPrice - (grandTotalPrice * (_discountAmount / 100))
        // }


        // setGrandTotalPrice(grandTotalPrice.toLocaleString("en-US", {
        //     maximumFractionDigits: 2,
        //     minimumFractionDigits: 2,
        // }))

        
    }


    return (
        <Grid container p={4} sx={{ bgcolor: 'white' }} >


            <Modal
                open={open}
                onClose={handleOpen}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Paper style={{ padding: 20, width: '80%' }}>
                    <Typography variant='h6' fontWeight={'bold'} >ชำระเงิน</Typography>
                    <ListOfProductOrders allProductOrders={allProductOrders}></ListOfProductOrders>
                    <Grid container justifyContent="center" sx={{ p: 3 }}>
                        <Button variant="contained" onClick={handlePayment} sx={{ borderRadius: "16px", bgcolor: '#FF7020', color: 'white' }} size="large">ยืนยันรายการอาหาร</Button>
                    </Grid>

                </Paper>
            </Modal>

            <Modal
                open={payment}
                onClose={handlePayment}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    right: '0',
                    transform: 'translate(0%, -50%)',
                    width: { xs: '90%', sm: '60%', md: '30%' },
                    height: '100%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }} >
                    <Typography variant='h6' fontWeight={'bold'}>ยอดการชำระเงิน</Typography>
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems='center' >
                        <div style={{ paddingRight: 15 }}>ยอดรวม</div>
                        <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 28 } }} style={{ fontWeight: 'bold' }}>{parseFloat(totalPrice).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })} ฿</Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems='center' >
                        <div style={{ paddingRight: 15 }}>ส่วนลด</div>
                        <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 28 } }} style={{ fontWeight: 'bold' }}>{parseFloat(discountAmount || "0").toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        }) || '0'} %</Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems='center' >
                        <div style={{ paddingRight: 15 }}>ยอดสุทธิ</div>
                        <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 28 } }} style={{ fontWeight: 'bold' }}>{parseFloat(totalAmount || "0").toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        }) || '0'} ฿</Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems='center' >
                        <div style={{ paddingRight: 15 }}>รับเงิน</div>
                        <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 28 } }} style={{ fontWeight: 'bold' }}>{parseFloat(getAmount || "0").toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        }) || '0'} ฿</Typography>
                    </Box>
                    <Divider></Divider>
                    {/* {
                        getAmount !== "0" ?
                            <Box display={'flex'} justifyContent={'flex-end'} alignItems='center' >
                                <div style={{ paddingRight: 15 }}>เงินทอน</div>
                                <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 28 } }} style={{ fontWeight: 'bold' }}>{grandTotalPrice} ฿</Typography>
                            </Box>
                            :
                            null
                    } */}
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems='center' >
                        <div style={{ paddingRight: 15 }}>เงินทอน</div>
                        <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 28 } }} style={{ fontWeight: 'bold' }}>{grandTotalPrice} ฿</Typography>
                    </Box>

                    <Grid container sx={{ mt: '14px' }}>
                        <Grid xs={3} onClick={() => addAmount('1')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>1</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => addAmount('2')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>2</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => addAmount('3')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>3</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => setDiscount(10, 'percent')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>10%</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={3} onClick={() => addAmount('4')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>4</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => addAmount('5')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>5</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => addAmount('6')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>6</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => setDiscount(20, 'percent')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>20%</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={3} onClick={() => addAmount('7')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>7</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => addAmount('8')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>8</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => addAmount('9')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>9</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => addAmount('CC')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'><BackspaceIcon style={{ color: '#FF7020' }} /></Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: '16px' }}>
                        <Grid xs={3} onClick={() => addAmount('C')}>
                            <Typography variant='h4' sx={styleBTN} style={{ color: '#FF7020' }} textAlign='center'>C</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => addAmount('0')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>0</Typography>
                        </Grid>
                        <Grid xs={3} onClick={() => addAmount('.')}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>.</Typography>
                        </Grid>
                        <Grid xs={3}>
                            <Typography variant='h4' sx={styleBTN} textAlign='center'>Add</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: '16px' }}>
                        <Grid xs={3} item>
                            <Button onClick={() => printReceipt()}
                                style={{
                                    background: 'black',
                                    color: 'white',
                                    borderRadius: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <LocalPrintshopIcon style={{ marginBottom: '8px' }} />
                                พิมใบเสร็จ
                            </Button>

                        </Grid>
                        <Grid xs={3}>

                            <Button style={{
                                background: 'black',
                                color: 'white',
                                borderRadius: '16px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <EmailIcon />
                                Email
                            </Button>
                        </Grid>
                        <Grid xs={3} item></Grid>
                        <Grid xs={3} item>


                            <Button onClick={() => savePaymentOrder()} style={{
                                background: '#FF7020',
                                color: 'white',
                                borderRadius: '16px',
                            }}>
                                <FileDownloadDoneIcon />
                                Done
                            </Button>
                            <Button onClick={() => {
                                setDiscountAmount(0);
                            }} style={{
                                background: '#FF7020',
                                color: 'white',
                                borderRadius: '16px',
                                marginTop: '.5rem',
                                marginRight: '.5rem'
                            }}>
                                <RestartAltIcon />
                                Reset Discount
                            </Button>


                        </Grid>
                    </Grid>
                </Box>
            </Modal>


            <Grid item xs={12} sm={12} md={12} lg={8}>

                <Box>
                    <Typography variant="h4" sx={{ flexWrap: 'nowrap', ml: { xs: 9, sm: 20, md: 30, lg: 0 } }}>
                        รายการอาหาร
                    </Typography>

                    <Paper
                        component="form"
                        elevation={0}
                        sx={{
                            maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '40%' },
                            border: '1px solid #ccc',
                            alignItems: 'center',
                            borderRadius: 7,
                            mt: { xs: 3, sm: 5, md: 5, lg: -5 },
                            ml: { xs: 0, sm: 0, md: 0, lg: 55 },
                        }}
                    >
                        <IconButton
                            style={{ borderRadius: 0, color: '#ccc', cursor: 'default' }}
                            sx={{ p: '10px', ml: { xs: 0, sm: 0, md: 0, lg: 0 } }}
                        >
                            <GridSearchIcon />
                        </IconButton>

                        <InputBase
                            onChange={e => onSearchMenu(e.target.value)}
                            sx={{ ml: { xs: 0, sm: 5, md: 5, lg: 5 }, flex: 1 }}
                            placeholder="Search Menu"
                        />
                    </Paper>

                </Box>

                {/* <Grid sx={{mt: 3,
            background: 'white',
            color: 'orange',
            }}>
                <Foodca menuItems={allCategorys} />
            </Grid> */}
                <br></br>
                <div style={{
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    whiteSpace: 'nowrap'
                }}>
                    <Button
                        variant='outlined'
                        onClick={() => setSelectCategoryMenu({ categoryId: '', categoryName: '' })}
                        sx={{
                            background: selectCategoryMenu?.categoryId == "" ? 'orange' : '',
                            color: selectCategoryMenu?.categoryId == "" ? 'white' : 'orange'
                        }}
                        style={{ borderRadius: 50, margin: 5, display: 'inline-block' }}
                    >ทั้งหมด</Button>
                    {allCategorys.map((item: any, index) => {
                        return (
                            <Button
                                key={index}
                                variant='outlined'
                                onClick={() => setSelectCategoryMenu(item)}
                                sx={{
                                    background: selectCategoryMenu?.categoryId == item.categoryId ? 'orange' : '',
                                    color: selectCategoryMenu?.categoryId == item.categoryId ? 'white' : 'orange'
                                }}
                                style={{ borderRadius: 50, margin: 5, display: 'inline-block' }}
                            >{item.categoryName}</Button>
                        )
                    })}
                </div>


                <Grid container spacing={2} p={2} >
                    {
                        allProductByCategorys.map((item: any, index) => (
                            <Grid item key={index} xs={6} sm={6} md={4} lg={3}   >
                                <Card sx={{ borderRadius: 3 }} elevation={1}>
                                    <CardMedia
                                        image={item.imageUrl}
                                        sx={{ height: 140 }} />
                                    <Typography
                                        sx={{ m: 1 }}
                                        variant="h6">{item.product_name}</Typography>
                                    <Typography
                                        sx={{ m: 1, color: "#FF7020", fontWeight: 'bold' }}
                                        variant='subtitle1'> ฿{item.price}</Typography>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} p={2} pt={4}>
                <ListOfProductOrders allProductOrders={allProductOrders}></ListOfProductOrders>
                <Box
                    textAlign={"right"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"end"}
                >
                    <Box display={'flex'} justifyContent={'space-between'} width={'100%'} pl={2}>
                        <Select
                            value={tableSelected}
                            onChange={(e) => setTableSelected(e.target.value)}
                            displayEmpty
                            size={'small'}
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ borderRadius: 10, color: 'black', height: 30, padding: 20, paddingRight: 25 }}
                        >
                            <MenuItem value={""}>เลือกโต๊ะ</MenuItem>
                            {
                                tables.map((item: any, index) => {
                                    return (
                                        <MenuItem key={index} value={item.id}>โต๊ะ {item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                        <div>รวม {allProductOrders.length} รายการ</div>
                    </Box>
                    <br></br>
                    <div>ยอดชำระทั้งหมด</div>
                    <div style={{ fontSize: 27 }}>{totalPrice} บาท</div>
                    <Box>
                        <Button
                            onClick={() => setOpen(true)}
                            size={"large"}
                            style={{
                                backgroundColor: "green",
                                color: "white",
                                borderRadius: 25,
                                padding: "10px 35px 10px 35px",
                            }}
                        >
                            ชำระเงิน
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid >
    )
}