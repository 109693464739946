import { Box, Divider, Drawer, IconButton, List, Theme, Toolbar,  } from '@mui/material';
import { MainListItems, secondaryListItems } from './ListItems';
import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
const drawerWidth: number = 240;


interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window?: () => Window;
    open: boolean
    toggleDrawer: () => void
}

export default function Navbar(props: Props) {

    const { window } = props;

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant="temporary"
                open={props.open}
                // onTransitionEnd={props.toggleDrawer}
                onClose={props.toggleDrawer}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    zIndex: 9999
                }}
            >
                <Toolbar

                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={props.toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <MainListItems toggleDrawer={props.toggleDrawer} ></MainListItems>
                    {/* <Divider sx={{ my: 1 }} />
                    {secondaryListItems} */}
                </List>
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                </Toolbar>
                <List component="nav">
                    <MainListItems toggleDrawer={props.toggleDrawer} />
                    {/* <Divider sx={{ my: 1 }} />
                    {secondaryListItems} */}
                </List>
            </Drawer>
        </Box>
    )
}