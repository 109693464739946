import { Button, FormControl, Grid, Typography } from "@mui/material"
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import React from "react";
import { StockService } from "../../../services/StockService";
import CustomSelect from "../../../components/Select/CustomSelect";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: 16,
        width: "",
        height: "23px",
        padding: "10px 12px",
        transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
        ]),
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

interface Props {
    onResponse: (stocks: Array<any>) => void;
}

const Filters = (props: Props) => {

    const [searchData, setSearchData] = React.useState<any>({})


    const setTextChange = (value: string, keyword: string) => {
        setSearchData({
            ...searchData,
            [keyword]: value
        })
    }

    const onSearch = () => {
        getStocks(searchData)
    }

    const getStocks = async (searchData: object) => {
        const res = await StockService.getStockMenusConfig(searchData);
        if (res?.status == 200) {
            const { stockMenus } = res.response;
            props.onResponse(stockMenus)
        }
    }


    return (
        <Grid container spacing={2} >
            {/* <Grid item >

                <FormControl variant="standard" sx={{ width: "100%" }}>
                    <Typography sx={{ width: "100%", right: 0 }}>หมวด</Typography>
                    <BootstrapInput onChange={(e) => setTextChange(e.target.value)} />
                </FormControl>
            </Grid> */}
            <Grid item >
                <FormControl variant="standard" sx={{ width: "100%" }}>
                    <Typography sx={{ width: "100%", right: 0 }}>รหัสสินค้า / เลขบาร์โค้ด</Typography>
                    <BootstrapInput onChange={(e) => setTextChange(e.target.value, 'barcode')} />
                </FormControl>
            </Grid>
            <Grid item >
                <FormControl variant="standard" sx={{ width: "100%" }}>
                    <Typography sx={{ width: "100%", right: 0 }}>ชื่อสินค้า</Typography>
                    <BootstrapInput onChange={(e) => setTextChange(e.target.value, 'product_name')} />
                </FormControl>
            </Grid>
            {/* <Grid item >
                <Typography>สถานะการขาย</Typography>
                <CustomSelect dataSource={[]} onChange={() => { }} />
            </Grid> */}
            {/* <Grid item >
                <FormControl variant="standard" sx={{ width: "100%" }}>
                    <Typography sx={{ width: "100%", right: 0 }}>จำนวนคงเหลือต่ำกว่า</Typography>
                    <BootstrapInput onChange={(e) => setTextChange(e.target.value, 'remain_quantity')} />
                </FormControl>
            </Grid> */}
            <Grid item >
                <Typography>&nbsp;</Typography>
                <Button
                    onClick={() => onSearch()}
                    variant="contained"
                    sx={{ bgcolor: "#FF9B28", height: 35, px: "9px", py: "10px", width: "100%" }}
                >
                    ค้นหา
                </Button>
            </Grid>
            <Grid item >

            </Grid>
        </Grid>
    )

}
export default Filters