import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, ButtonGroup, Divider, FormControl, InputBase, InputLabel, Grid, TextareaAutosize } from "@mui/material";
import { DataGrid, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import { ArrowDropDown, CalendarMonthOutlined, PersonOutline } from "@mui/icons-material";
import React from "react";
import { alpha, styled } from '@mui/material/styles';
import QRCode from 'react-qr-code';
import axios from "axios";
import { io } from "socket.io-client";
import EditCustomerSeats from "./EditCustomerSeats";
const socket = io(`${process.env.REACT_APP_API_URL}`, { transports: ['websocket', 'polling'] });

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
        borderRadius: 19,
        position: 'relative',
        // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));


const BootstrapTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
        borderRadius: 19,
        position: 'relative',
        // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));





const columns: GridColDef[] = [
    { field: 'id', headerName: 'ชื่อลูกค้า', headerAlign: 'center', align: 'center', flex: 1 },
    { field: 'firstName', headerName: 'เวลา', headerAlign: 'center', align: 'center', flex: 1 },
    {
        field: 'lastName', headerName: 'โต๊ะ', headerAlign: 'center', align: 'center', flex: 1, renderCell: () => (
            <Box>
                T-1
            </Box>
        )
    },
    {
        field: 'age',
        headerName: 'ORDER',
        headerAlign: 'center', align: 'center',
        renderCell: () => (
            <Box display={'flex'} justifyContent={'center'}>
                <PersonOutline fontSize="small" />
                12
            </Box>
        )
    },
];

const rows = [
    { id: 'Joe Doe', lastName: 'Snow', firstName: '08:00 AM', age: 35 }
];


export default function TableOnCustomers(props: any) {

    const [isDailog, setIsDialog] = React.useState<boolean>(false);
    const [fullWidth, setFullWidth] = React.useState<boolean>(true);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
    const [token, setToken] = React.useState("");
    const [loaded, setLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/tables/get/token`, {
            table_id: props.selectedTable.id
        }).then((response) => {
            if (response.data.message == 'OK') {
                setToken(response.data.token);
                setLoaded(true);
            }
        })
    }, [props]);
    const onclickremove = () =>{
        // socket.emit()
        const update = props.alram.filter((prev: string) => prev !== props.selectedTable.name);
        props.setAlram(update);
        props.stopAlarm();
    } 


    const generateQRCode = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/tables/generate/qrcode`, {
            id: props.selectedTable.id
        }).then((response) => {
            if (response.data.message == 'OK') {
                setToken(response.data.token);
                props.reloadTables();
            }
        })
    }

    const resetQRCode = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/tables/resetqrcode`, {
            table_id: props.selectedTable.id
        }).then((response) => {
            if (response.data.message == 'OK') {
                setToken("");
                props.reloadTables();
            }
        })
    }

    return (
        <Box
            sx={{ p: 5 }}
        >
            <Dialog
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        setIsDialog(false)
                    }
                }}
                open={isDailog}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{ sx: { borderRadius: "13px" } }}

            >

                <DialogTitle id="form-dialog-title">รายละเอียด</DialogTitle>
                <Divider></Divider>
                <DialogContent sx={{ p: 3 }}>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="bootstrap-input">
                            ชื่อ - นามสกุล
                        </InputLabel>
                        <BootstrapInput defaultValue="" id="bootstrap-input" />
                    </FormControl>

                    <Grid container sx={{ pt: 2 }}>
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="bootstrap-input">
                                อีเมล
                            </InputLabel>
                            <BootstrapInput defaultValue="" id="bootstrap-input" />
                        </FormControl>
                        &nbsp;&nbsp;
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="bootstrap-input">
                                เบอร์โทรศัพท์
                            </InputLabel>
                            <BootstrapInput defaultValue="" id="bootstrap-input" />
                        </FormControl>
                    </Grid>
                    <br></br>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            บันทึกการเยี่ยมชม
                        </InputLabel>
                        <BootstrapTextareaAutosize
                            placeholder="รายละเอียด"
                            style={{ borderRadius: 19, padding: 10 }}
                            minRows={3}
                        ></BootstrapTextareaAutosize>
                    </FormControl>


                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button fullWidth size={"large"} style={{
                                border: "1px solid #ff6801", color: "#ff6801",
                                borderRadius: 25,
                                padding: '10px 35px 10px 35px'
                            }} >ยกเลิก</Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button fullWidth size={"large"} style={{
                                backgroundColor: "#ff6801", color: "white",
                                borderRadius: 25,
                                padding: '10px 35px 10px 35px'
                            }} >จอง</Button>
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>



            {/* <Box textAlign={'right'} >
                <FormControl sx={{ mt: 2, pr: 1 }}>
                    <Button fullWidth size={"large"} style={{ backgroundColor: "#7b69e8", color: "white", borderRadius: 10 }} >ย้ายโต๊ะ / จองโต๊ะ</Button>
                </FormControl>

                <FormControl sx={{ mt: 2, pr: 1 }}>
                    <Button fullWidth size={"large"} style={{ backgroundColor: "#e2e2e2", color: "black", borderRadius: 10 }} >ส่งไปยังครัว</Button>
                </FormControl>
            </Box> */}




            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', paddingTop: 10 }}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#3395f0',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid #91caff'
                }}></div>&nbsp;&nbsp;มีอยู่</div>
                &nbsp;&nbsp;
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#8e3dfd',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(190 151 245)'
                }}></div>&nbsp;&nbsp;จองแล้ว</div>
                &nbsp;&nbsp;

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#14c91c',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(207 238 208)'
                }}></div>&nbsp;&nbsp;เรียกเก็บเงินแล้ว</div>
                &nbsp;&nbsp;
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#f0b433',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(253 219 147)'
                }}></div>&nbsp;&nbsp;กำลังว่าง</div>
            </div> */}

            {/* <br></br>

            <div style={{ color: '#7a7a7a', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <span>เลือกวันที่</span>
                <span style={{
                    verticalAlign: 'middle',
                    display: 'inline-flex'
                }}>March 2023 <ArrowDropDown></ArrowDropDown></span>
            </div>

            <br></br>
            <Box textAlign={'center'}>
                <ButtonGroup
                    sx={{
                        ".MuiButtonGroup-grouped": {
                            borderColor: "#ccc",
                        },
                    }}
                    variant="outlined"
                >

                    <Button style={{ borderTopLeftRadius: 14, borderBottomLeftRadius: 14 }}>
                        <CalendarMonthOutlined style={{ color: '#ff7900' }}></CalendarMonthOutlined>
                    </Button>
                    {
                        ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((item, index) => {
                            return (
                                <Button style={index == 6 ? { borderTopRightRadius: 14, borderBottomRightRadius: 14 } : {}}>
                                    <Box display={'grid'}>
                                        <div style={{ fontSize: 12, color: '#a7a7a7', fontWeight: 600, textTransform: 'capitalize' }}>{item}</div>
                                        <div style={{ fontWeight: 'bold', color: 'black', fontSize: 16 }}>{index + 2}</div>
                                    </Box>
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </Box> */}


            {/* <br></br>
            <Box sx={{ width: { xs: 400, sm: 400, md: '100%' } }} style={{ overflow: 'auto' }} >
                <DataGrid
                    sx={{
                        width: { xs: 400, sm: 400, md: '100%' },
                        border: 0
                    }}
                    // getRowId={(row: GridRowModel) => row.id}
                    // getRowHeight={() => 'auto'}
                    disableEval
                    // showCellVerticalBorder={true}
                    disableColumnMenu
                    disableColumnFilter
                    rows={rows}
                    columns={columns}
                    hideFooter
                    onRowClick={() => {
                        setIsDialog(true)
                    }}
                />
            </Box>
            <br></br> */}
            {
                loaded && <>
                    โต๊ะ <b>{props.selectedTable.name}</b> {
                        props.selectedTable.haved_token == 1 && <small>(ลูกค้าเข้าเมื่อเวลา: <b>{props.selectedTable.date_generated} {props.selectedTable.time_generated}</b>)</small>
                    }
                    {
                        token != "" ? <Button fullWidth size={"large"} style={{
                            backgroundColor: "#e2e2e2", color: "black",
                            borderRadius: 25,
                            padding: '10px 35px 10px 35px',
                            marginBottom: '1rem'
                        }} onClick={resetQRCode}>
                            ลบ QR CODE
                        </Button> : <Button fullWidth size={"large"} style={{
                            backgroundColor: "#ff6801", color: "white",
                            borderRadius: 25,
                            padding: '10px 35px 10px 35px',
                            marginBottom: '1rem'
                        }} onClick={generateQRCode}>
                            {
                                "สร้าง QR CODE ใหม่"
                            }
                        </Button>
                    }

                    {
                        token != "" && <QRCode value={`${process.env.REACT_APP_BASE_URL}/qrcode/${token}`} style={
                            {
                                width: '100%'
                            }
                        } />
                    }

                    {
                        token != "" && <EditCustomerSeats selectedTable={props.selectedTable} reloadTables={props.reloadTables} />
                    }
                </>
            }
            <br /><br/>
            <Grid container justifyContent='center'>
                <Button onClick={onclickremove} variant="contained" style={
                    {
                        color: "white"
                    }
                }>พนักงานมาถึงโต๊ะแล้ว</Button>
            </Grid>
        </Box >
    )
}