import React, { ChangeEvent, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  IconButton,
  Modal,
} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LuClipboardList } from "react-icons/lu";
import { GoChecklist } from "react-icons/go";
import './CastomCss.css'
import axios from "axios";

type data = {
  id: number;
  name_title: string;
  no_bill: number;
  partner: string;
  date_: string;
  vat: number;
  total_all: number;
  list_item: number;
}

type info = {
  list_name: string;
  type: string;
  amount: number;
  unit_name: string;
  unit_price: number;
  total: number;
}

export default function Audit() {
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selected, setSelected] = useState<any>({});
  const [dataTable, setDataTable] = useState<data[]>([]);
  const [dataInfo, setDataInfo] = useState<info[]>([]);
  const [popUp, setPopUp] = useState<boolean>(false);


  const handleOpen = (row: any) => {
    setPopUp(!popUp)
    setSelected(row)
    axios.get(`${process.env.REACT_APP_API_URL}/account/get/accountlist/${row.no_bill}`)
      .then(response => {
        setDataInfo(response.data.message)
      })
      .catch(error => {
        console.error('Error Get request:', error);
      });
  };

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(event.target.value);
    axios.get(`${process.env.REACT_APP_API_URL}/account/get/accountcategory`)
      .then(response => {
        setDataTable(response.data.message)
      })
      .catch(error => {
        console.error('Error Get request:', error);
      });
  };
  const search = () => {

    const newdata = dataTable.filter((prev) => prev.date_ === selectedYear)
    if (!selectedYear) {
      alert('กรุณาใส่ข้อมูล')
    } else {
      setDataTable(newdata)
    }


  }
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/account/get/accountcategory`)
      .then(response => {
        setDataTable(response.data.message)
      })
      .catch(error => {
        console.error('Error Get request:', error);
      });



  }, [])
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '20px',
    display: "flex",
    flexDirection: "column",
    borderRadius: '38px',
    '@media (max-width: 770px)': {
      width: '100%',
    },
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container sx={{ flexGrow: 1, width: '100%', }} >
      <Grid item direction="row" justifyContent="flex-start" alignItems="center" xs={12} md={12} sx={{
        bgcolor: "#FFB802",
        width: "100%",
        mb: 2,
        mt: 0,
        ml: { xs: 0, md: 0 },
        p: 3,
      }}>
        <Typography sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}>
          จัดทำข้อมูลรายจ่าย
        </Typography>
      </Grid>

      <Paper sx={{ width: { xs: '95%', md: '98%' } }}>
        <Grid container justifyContent='end' sx={{ p: '16px' }}>
          <Grid item>
            <input type="date" onChange={handleYearChange} style={{ height: '43px', borderRadius: '12px', width: '143px', marginRight: '12px' }} />

          </Grid>
          <Grid item >
            <Button
              onClick={search}
              size="large"
              color="inherit"
              style={{ borderRadius: 4, background: '#FF9B28', color: '#FFFFFF' }}
              sx={{
                width: { xs: '100%', md: '151px' },
                fontSize: '15px',
              }}
            >
              ค้นหา
            </Button>
          </Grid>
        </Grid>

      </Paper>


      <Grid item xs={12} md={12} sx={{ mt: 2, overflowX: 'auto', marginLeft: { xs: 1, sm: 2, md: 2 } }}>
        <Table sx={{ borderRadius: '8px', width: { xs: '100%', sm: '100%', md: '99%' } }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', borderRadius: '8px 0px 0px 0px' }}>วันที่จ่าย</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>หัวข้อ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>บริษัทคู่ค้า</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>รายการ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>ยอดเงิน</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', borderRadius: '0px 8px 0px 0px' }}>จัดการ</TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={isMobile ? { flexDirection: 'column' } : {}}>
            {dataTable.map((row, index) => (
              <TableRow key={index} sx={isMobile ? { flexDirection: 'column' } : {}}>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.date_}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.name_title}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.partner}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.list_item}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.total_all}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>
                  <IconButton color="primary" aria-label="edit" onClick={() => handleOpen(row)}>
                    <GoChecklist style={{ color: '#FFFFFF', background: '#FFB969', width: '37.77px', height: '36.94px', borderRadius: '8px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Modal
        open={popUp}
        onClose={handleOpen}
        sx={{ overflow: 'scroll' }}
      >
        <Box sx={style}>
          {/* คอนเทรนเนอร์ */}
          <Grid container alignItems='center' justifyContent='center' sx={{ width: '95%' }}>

            {/* ด้านบน */}
            <Grid width='50%' sx={{
              overflow: 'auto',
              '@media (max-width: 770px)': {
                width: '100%',
              },
            }}>
              <Grid container justifyContent='space-between'>
                <p> <b>หัวข้อรายการ</b> {selected.name_title} </p>
                <p> <b>เลขที่ใบเสร็จ</b> {selected.no_bill}</p>
              </Grid>
              <Grid container justifyContent='space-between'>
                <p> <b>วันที่</b> {selected.date_} </p>
                <p> <b>บริษัทคู่ค้า</b> {selected.partner}</p>
              </Grid>
              <Grid container justifyContent='space-between'>
                <p> <b>VAT</b> {selected.vat} </p>
              </Grid>
            </Grid>

            {/* ด้านล้าง */}
            <Grid width='100%' sx={{ overflowX: 'auto' }}>
              <TableBody>
                {dataInfo.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow sx={{ flexDirection: 'column' }}>
                      <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '8%' }}>{index + 1}</TableCell>
                      <TableCell style={{ textAlign: 'left', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '20%' }}>{row.list_name}</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '12%' }}>{row.type}</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '8%' }}>{row.amount}</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '8%' }}>{row.unit_name}</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '12%' }}>{row.unit_price}</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '12%' }}>{row.total}</TableCell>
                      <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '8%' }} colSpan={8 - 1}>
                        {/* <IconButton color="default" aria-label="view">
                          <DeleteOutlineIcon style={{ color: '#FFFFFF', background: '#FF9696', width: '37.77px', height: '36.94px', borderRadius: '8px' }} />
                        </IconButton> */}
                      </TableCell>
                    </TableRow>


                  </React.Fragment>))}
                <>
                  <TableRow>
                    <TableCell style={{ textAlign: 'left', fontSize: '18px', border: '1px solid #D9D9D9' }} colSpan={6}>ยอดรวม
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}>
                      {selected.total_all}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ textAlign: 'left', fontSize: '18px', border: '1px solid #D9D9D9' }} colSpan={6}>ยอดสุทธิก่อนหักภาษี</TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}>
                      {selected.total_all}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ textAlign: 'left', fontSize: '18px', border: '1px solid #D9D9D9' }} colSpan={6}>คำนวนภาษีมูลค่าเพิ่ม</TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}>{(selected.total_all) / 100 * selected.vat}</TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ textAlign: 'left', fontSize: '18px', border: '1px solid #D9D9D9' }} colSpan={6}>ยอดรวมทั้งหมด</TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}>
                      {selected.total_all + ((selected.total_all) / 100 * selected.vat)}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}></TableCell>
                  </TableRow>
                </>
              </TableBody>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid >
  );
}