import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import React from "react";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "",
    height: "23px",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface CustomInputTextProp {
  onKeyPress?: (e: any) => void;
  onChange: (value: string) => void
}

export default function InputText(props: CustomInputTextProp) {

  const [text, setText] = React.useState<string>("")

  return (
    <FormControl variant="standard" sx={{ width: "100%" }}>
      <BootstrapInput
        value={text}
        onKeyPress={props?.onKeyPress}
        onChange={(e) => {
          setText(e.target.value)
          props.onChange(e.target.value)
        }}
      />
    </FormControl>
  );
}
