import { Button, Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, ChangeEvent, useState } from 'react'
import FaceData from './faceCostomer.json'
import DeleteIcon from '@mui/icons-material/Delete';
import FeedIcon from '@mui/icons-material/Feed';
import axios from 'axios';
function ReturnProduct() {
    const [reload, setReload] = useState<any>([])
    const [log, setLog] = useState<any>([])
    const [search, setSearch] = useState<number>(0)
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/log/getlogcancel`)
            .then(res => {
                setLog(res.data.message)
            })
    }, [reload])
    const onsearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(parseFloat(e.target.value))
    }
    const onclicksearch = () => {
        setLog(log.filter((item :any)=>item.id === search))
    } 

    const deletelog = async (id: number) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/log/cancelorder/${id}`)
            .then(res => setReload((prev: any) => [...prev, res.data.message]))
    }
    const inputremovepading = {
        style: {
            padding: 5
        }
    }
    return (
        <Grid container>
            <Grid sm={12} sx={{ bgcolor: '#FFB802', p: 3, width: '100%' }}>
                <Typography
                    sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}
                >
                    การยกเลิกออเดอร์
                </Typography>
            </Grid>
            <Card sx={{ width: "100%", m: "6px" }}>
                <Grid container p={1.5}>
                    <Grid xs={10} sm={3} lg={2.5} sx={{
                        m: 1
                    }}>
                        <Typography variant='subtitle1' sx={{
                            '@media (max-width: 910px)': {
                                fontSize: '12px'
                            },
                        }}>ลำดับ</Typography>
                        <TextField type='number' onChange={onsearch} inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={2.5} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1' sx={{
                            '@media (max-width: 910px)': {
                                fontSize: '12px'
                            },
                        }}>เบอร์โทร</Typography>
                        <TextField inputProps={inputremovepading} variant='outlined' />
                    </Grid>
                    <Grid xs={10} sm={2.5} lg={2.5} sx={{ m: 1 }}>
                        <Typography variant='subtitle1' sx={{
                            height: '50%',
                            '@media (max-width: 910px)': {
                                display: 'none'
                            },
                        }}></Typography>
                        <Button
                            onClick={onclicksearch}
                            sx={{
                                color: 'white',
                                fontSize: '10px',
                                height: '50%',
                                bgcolor: '#79C447',
                                '@media (max-width: 770px)': {
                                    height: '100%'
                                },
                            }} variant='contained'>ค้นหา</Button>
                    </Grid>



                </Grid>

            </Card>
            <Card sx={{ width: "100%", m: "6px" }}>
                <TableContainer component={Paper} sx={{ minHeight: 320 }}>
                    <Table sx={{ m: "6px", minWidth: 700 }}>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#e5e5e5" }}>
                                <TableCell align="center">ลำดับ</TableCell>
                                <TableCell align="center">ลูกค้า</TableCell>
                                <TableCell align="center">รายการอาหาร</TableCell>
                                <TableCell align="center">จำนวณ</TableCell>
                                <TableCell align="center">จัดการ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                log.map((data: any) => (
                                    <TableRow>
                                        <TableCell align="center">{data.id}</TableCell>
                                        <TableCell align="center">ทั่วไป</TableCell>
                                        <TableCell align="center">{data.menu_name}</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">
                                            <Grid container alignItems='center' justifyContent='center' columnGap='12px'>
                                                {/* <Grid container alignItems='center' justifyContent='center' sx={{ borderRadius: '6px', cursor: 'pointer', width: '37.77px', height: '36.94px', bgcolor: '#ffb80291' }}>
                                                    <FeedIcon sx={{ color: 'white' }} />
                                                </Grid> */}
                                                <Grid container onClick={() => deletelog(data.id)} alignItems='center' justifyContent='center' sx={{ borderRadius: '6px', cursor: 'pointer', width: '37.77px', height: '36.94px', bgcolor: '#ff000094' }}>
                                                    <DeleteIcon sx={{ color: 'white' }} />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>

    )
}

export default ReturnProduct