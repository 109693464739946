import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Stock from "../../../models/stock";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  Divider,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Swal from 'sweetalert2';
import _ from "lodash";
import TableBody from "@mui/material/TableBody";
import { AddBox, Delete } from "@mui/icons-material";
import { StockService } from "../../../services/StockService";
import CustomInputText from "./CustomInputText";
import AddStockSettings from "./AddStockSettings";



const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "",
    height: "23px",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "114px",
  },
}));

interface CustomEditModalProp {
  isOpen: boolean;
  stock: Stock;
  stocks?: Array<any>
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    // padding: theme.spacing(1),
  },
  "& .MuiDialog-container": {},
}));

export default function CustomEditModal(props: CustomEditModalProp) {
  const { isOpen, stock, onClose } = props;
  const [selectedStock, setSelectedStock] = React.useState("");
  const [allStocks, setAllStocks] = React.useState<Array<any>>([]);
  const [openAddStockConfig, setOpenAddStockConfig] = React.useState<boolean>(false);



  const handleChange = (event: SelectChangeEvent) => {
    const selectedId = event.target.value
    setSelectedStock(selectedId);

    const foundStock = _.find(props.stocks, { id: selectedId })
    if (foundStock) {
      const allStocks = foundStock.StockMenus.map((item: any) => {
        return {
          ...item.Stock,
          menuconfig_id: item.id
        }
      })
      setAllStocks(allStocks)
    }
  };

  const deleteStockMenuConfig = async (item: any, index: number) => {

    const res = await StockService.deleteStockMenuConfig(item.menuconfig_id)
    if (res?.status == 200) {
      const _allStocks = [...allStocks]
      _allStocks.splice(index, 1)
      setAllStocks(_allStocks)
      Swal.fire({
        icon: "success",
        text: res?.message
      });
    } else {
      Swal.fire({
        icon: "error",
        text: res?.message
      });
    }

  }

  const onChangeTextEnd = async (valueText: string, item: any) => {

    const res = await StockService.updateStockMenuConfig(item.menuconfig_id, {
      value: valueText
    })
    if (res?.status == 200) {
      Swal.fire({
        icon: "success",
        text: res?.message
      });
    } else {
      Swal.fire({
        icon: "error",
        text: res?.message
      });
    }
  }

  const onAdd = () => {
    setOpenAddStockConfig(true)
  }


  return (
    <React.Fragment>

      <BootstrapDialog onClose={() => { setOpenAddStockConfig(false) }} open={openAddStockConfig} fullWidth maxWidth="md">
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "#FFB802" }}
          id="customized-dialog-title"
        >
          <Typography
            sx={{ color: "white", fontSize: "24px", fontWeight: 600 }}
          >
            เพิ่มตั้งค่าสต๊อก
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => { setOpenAddStockConfig(false) }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ width: "100%", flexGrow: 1 }}>
          <AddStockSettings
            menuId={`${stock.id}`}
            onClose={() => setOpenAddStockConfig(false)}
            onResponse={(stockMenu: any) => {

              setAllStocks([
                ...allStocks,
                stockMenu
              ])
            }}
          ></AddStockSettings>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog onClose={onClose} open={isOpen} fullWidth maxWidth="md">
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "#FFB802" }}
          id="customized-dialog-title"
        >
          <Typography
            sx={{ color: "white", fontSize: "24px", fontWeight: 600 }}
          >
            ตั้งค่าตัดสต๊อก
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ width: "100%", flexGrow: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="start"
            sx={{ width: "100%" }}
          >
            <Typography sx={{ mr: 2, fontSize: "18px", fontWeight: 500 }}>
              รหัส/เลขบาร์โค้ด {stock.productCode} ชื่อสินค้า:
              {stock.productName}
            </Typography>



            <FormControl>
              <Select
                fullWidth
                value={selectedStock}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ height: "45px", width: "449px" }}
              >
                {
                  props.stocks?.map(s => {
                    return <MenuItem value={s.id}>{s.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>

          </Stack>
          <Box sx={{ pt: 2 }}>
            <Divider />
          </Box>
          <TableContainer>
            <Table
              className="tableBorder"
              sx={{ minWidth: 700 }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      รหัสสินค้า
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      รายการ
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      จำนวน
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      หน่วย
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                      จัดการ
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {
                  allStocks.map((item: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell component="th" scope="row" align="center">
                          {item.barcode}
                        </StyledTableCell>
                        <StyledTableCell align="center">{item.name}</StyledTableCell>
                        <StyledTableCell
                          sx={{ width: "100px" }}
                          padding="none"
                          align="center"
                        >

                          <CustomInputText
                            onChangeTextEnd={text => onChangeTextEnd(text, item)}
                            value={item.quantity}
                            label="" />

                        </StyledTableCell>
                        <StyledTableCell padding="none" align="center">
                          {item.unit}
                        </StyledTableCell>
                        <StyledTableCell padding="none" align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            sx={{ flexGrow: 1, mx: 2 }}
                          >
                            <IconButton
                              onClick={() => deleteStockMenuConfig(item, index)}
                              sx={{
                                bgcolor: "#FF9696",
                                borderRadius: "8px",
                                ":hover": { bgcolor: "#FF9696" },
                                mr: 2,
                              }}
                            >
                              <Delete sx={{ color: "white" }} />
                            </IconButton>
                            <IconButton
                              onClick={() => onAdd()}
                              sx={{
                                bgcolor: "#79C447",
                                borderRadius: "8px",
                                ":hover": { bgcolor: "#79C447" },
                                mr: 2,
                              }}
                            >
                              <AddBox sx={{ color: "white" }} />
                              <Typography

                                sx={{
                                  fontWeight: 600,
                                  fontSize: "18px",
                                  color: "white",
                                  px: 1,
                                }}
                              >
                                เพิ่ม
                              </Typography>
                            </IconButton>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                    )
                  })
                }



              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
