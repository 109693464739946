import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { MdUnfoldMore } from "react-icons/md";
import { SiAddthis } from "react-icons/si";
import './CastomCss.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import axios from "axios";

type info = {
  number: number;
  list: string;
  type: string;
  quantity: number;
  unit: string;
  cost: number;
  total: number;
  bill_id: number;
}
type account_category = {
  name_title: string;
  no_bill: number;
  partner: string;
  date_: string;
  vat: string;
}
export default function Account() {
  const [selectedList, setSelectedList] = useState<string>("");
  const [selectedReceipt, setSelecteReceipt] = useState<number>(0);
  const [selectedPartner, setSelectedPartner] = useState<string>("ไม่ระบุ");
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedVat, setSelectedVat] = useState<number>(0);
  // const [date, setDate] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<number>(0);
  const [selectedNot, setSelectedNot] = useState<string>("");
  const [selectedWhite, setSelectedWhite] = useState<string>("");
  const [selectedSpecified, setSelectedSpecified] = useState<number>(0);
  const [selectedNone, setSelectedNone] = useState<string>("");
  const [expenseinFormation, setExpenseinFormation] = useState<info[]>([]);
  const [accountCategory, setAccountCategory] = useState<account_category[]>([])
  const [total, setTotal] = useState<number>(0);


  useEffect(() => {
    setTotal(expenseinFormation.reduce((a, b) => a + b.total, 0))
  }, [expenseinFormation])

  const onClickSubMit = () => {
    if (!selectedList || !selectedReceipt || !selectedPartner || !selectedYear || expenseinFormation.length === 0) {
      return alert('กรุณากรอกให้ครบทุกช่อง')
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/account/add/account_category`, {
        name_title: selectedList,
        no_bill: selectedReceipt,
        partner: selectedPartner,
        date_: selectedYear,
        vat: selectedVat,
        total_all: total + (total / 100 * selectedVat),
        list_item: expenseinFormation.length
      }).then(response => {
        console.log('POST request successful');
      })
        .catch(error => {
          console.error('Error while making POST request:', error);
        });
      axios.post(`${process.env.REACT_APP_API_URL}/account/add/account_list`, expenseinFormation).then(response => {
        console.log('POST request successful');
      })
        .catch(error => {
          console.error('Error while making POST request:', error);
        });
    }
    setSelectedList('')
    setSelecteReceipt(0)
    setSelectedPartner('ไม่ระบุ')
    setSelectedYear('')
    setSelectedVat(0)
    setExpenseinFormation([])
  };

  const handleList = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedList(event.target.value);
  };
  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedYear(value);
    console.log(value)
  };

  const handleVat = (event: SelectChangeEvent<string>) => {
    setSelectedVat(parseFloat(event.target.value));
  };

  const handleReceipt = (event: ChangeEvent<HTMLInputElement>) => {
    setSelecteReceipt(parseFloat(event.target.value));
  };

  const handlePartner = (event: SelectChangeEvent) => {
    setSelectedPartner(event.target.value);
  };

  const handleNot = (event: SelectChangeEvent) => {
    setSelectedNot(event.target.value);
  };

  const handleProduct = (event: number) => {
    setSelectedProduct(event);
  };

  const handleWhite = (event: SelectChangeEvent) => {
    setSelectedWhite(event.target.value);
  };

  const handleSpecified = (event: number) => {
    setSelectedSpecified(event);
  };

  const handleNone = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedNone(event.target.value);
  };
  const handleOnclickAddList = () => {
    if (!selectedNone || !selectedNot || !selectedProduct || !selectedWhite || !selectedSpecified) {
      return alert('กรุณากรอกให้ครบทุกช่อง')
    } else {
      setExpenseinFormation((info) => [...info, { number: info.length + 1, list: selectedNone, type: selectedNot, quantity: selectedProduct, unit: selectedWhite, cost: selectedSpecified, total: selectedProduct * selectedSpecified, bill_id: selectedReceipt }])
    }
    setSelectedNone('')
    setSelectedNot('')
    setSelectedProduct(0)
    setSelectedWhite('')
    setSelectedSpecified(0)
  }
  const deleteitem = (number: number) => {
    const newdata = expenseinFormation.filter((prev) => prev.number !== number)
    setExpenseinFormation(newdata)
  }


  const data = [
    { number: 1, list: 'excemple', type: 'Lorem', quantity: 0, unit: 0, cost: 100, total: 6000, handle: '', },
    // Add more data as needed
  ];
  const inputremovepading = {
    style: {
      padding: 5
    }
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container sx={{ flexGrow: 1, width: '100%' }}>
      <Grid item direction="row" justifyContent="flex-start" alignItems="center" xs={12} md={12} sx={{
        bgcolor: "#FFB802",
        width: "100%",
        mb: 2,
        mt: 0,
        ml: { xs: 0, md: 0 },
        p: 3,
      }}>
        <Typography sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}>
          จัดทำข้อมูลรายจ่าย
        </Typography>
      </Grid>
      <Paper sx={{ margin: { xs: 1, sm: 2, md: 2 }, width: '100%' }}>
        <Grid container>
          <Grid item xs={12} md={3} margin='6px'>
            <Typography>หัวข้อรายการ</Typography>
            <TextField inputProps={inputremovepading}
              sx={{
                width: { xs: '100%', md: '100%' },
                height: '42px',
                borderRadius: '8px',

              }}
              variant="outlined"
              fullWidth
              value={selectedList}
              onChange={handleList}
            />
          </Grid>

          <Grid item xs={12} md={3} margin='6px'>
            <Typography>เลขที่ใบเสร็จ</Typography>
            <TextField inputProps={inputremovepading}
              type="number"
              sx={{
                width: { xs: '100%', md: '100%' },
                height: '42px',
                borderRadius: '8px',
              }}
              variant="outlined"
              fullWidth
              value={selectedReceipt}
              onChange={handleReceipt}
            />
          </Grid>

          <Grid item xs={12} md={3} margin='6px' >
            <Typography>บริษัทคู่ค้า</Typography>

            <Select
              onChange={(event) => handlePartner(event)}
              value={selectedPartner}
              sx={{
                width: { xs: '100%', md: '100%' },
                borderRadius: '8px',
              }}
            >
              <MenuItem value="ไม่ระบุ" >ไม่ระบุ</MenuItem>
              <MenuItem value="โลตัส" >โลตัส</MenuItem>
              <MenuItem value="บิ๊กซี">บิ๊กซี</MenuItem>
            </Select>

          </Grid>

          <Grid item xs={12} md={3} margin='6px' >
            <Typography>วันที่</Typography>
            <input type="date"
              style={{ width: '100%', padding: '6px', borderRadius: '4px', borderColor: "#d9d9d9" }}
              onChange={handleYearChange}
              value={selectedYear}
            />

          </Grid>

          <Grid item xs={12} md={3} margin='6px' >
            <Typography>VAT</Typography>
            <Select
              onChange={(event: SelectChangeEvent<string>) => handleVat(event)}
              value={selectedVat.toString()}
              sx={{
                width: { xs: '100%', md: '100%' },
                borderRadius: '8px',
              }}
            >
              <MenuItem value={0} >ไม่มี vat</MenuItem>
              <MenuItem value={5} >7%</MenuItem>
              <MenuItem value={7}>5%</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Paper>

      <Grid item xs={12} md={12} sx={{ mt: 2, overflowX: 'auto', margin: { xs: 1, sm: 2, md: 2 }, width: '100%' }}>
        <Table sx={{ borderRadius: '8px', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', borderRadius: '8px 0px 0px 0px', width: '8%' }}>ลำดับ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', width: '20%' }}>รายการ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', width: '12%' }}>ประเภท</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', width: '8%' }}>จำนวน</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', width: '8%' }}>หน่วย</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', width: '12%' }}>ราคาต่อหน่วย</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', width: '12%' }}>ยอดรวม (บาท)</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', borderRadius: '0px 8px 0px 0px', width: '8%' }}>จัดการ</TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{ flexDirection: 'column' }}>
            {expenseinFormation.map((row:any, index:number) => (
              <React.Fragment key={index}>
                <TableRow sx={{ flexDirection: 'column' }}>
                  <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '8%' }}>{row.number}</TableCell>
                  <TableCell style={{ textAlign: 'left', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '20%' }}>{row.list}</TableCell>
                  <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '12%' }}>{row.type}</TableCell>
                  <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '8%' }}>{row.quantity}</TableCell>
                  <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '8%' }}>{row.unit}</TableCell>
                  <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '12%' }}>{row.cost}</TableCell>
                  <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '12%' }}>{row.total}</TableCell>
                  <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9', width: '8%' }} colSpan={8 - 1}>
                    <IconButton color="default" aria-label="view" onClick={() => deleteitem(row.number)}>
                      <DeleteOutlineIcon style={{ color: '#FFFFFF', background: '#FF9696', width: '37.77px', height: '36.94px', borderRadius: '8px' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>


              </React.Fragment>))}
            <>
              <TableRow>
                <TableCell style={{ textAlign: 'left', fontSize: '18px', border: '1px solid #D9D9D9' }} colSpan={6}>ยอดรวม
                </TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}>
                  {total}
                </TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textAlign: 'left', fontSize: '18px', border: '1px solid #D9D9D9' }} colSpan={6}>ยอดสุทธิก่อนหักภาษี</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}>
                  {total}
                </TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textAlign: 'left', fontSize: '18px', border: '1px solid #D9D9D9' }} colSpan={6}>คำนวนภาษีมูลค่าเพิ่ม</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}>{total / 100 * selectedVat}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textAlign: 'left', fontSize: '18px', border: '1px solid #D9D9D9' }} colSpan={6}>ยอดรวมทั้งหมด</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}>
                  {total + (total / 100 * selectedVat)}
                </TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}></TableCell>
              </TableRow>
              <TableRow>
                {/* ------------------------------------------------------------------------------------------------------ */}
                <TableCell style={{ textAlign: 'left', fontSize: '18px', border: '1px solid #D9D9D9' }} colSpan={6}>

                  <Typography>รายการสินค้า</Typography>
                  <Grid container justifyContent='space-around'>
                    <TextField inputProps={inputremovepading}
                      sx={{
                        width: { xs: '100%', md: '30%' },
                        height: '45px',
                        borderRadius: '8px',
                        m: { xs: 0, md: 0 },

                      }}
                      variant="outlined"
                      fullWidth
                      value={selectedNone}
                      onChange={handleNone}
                    />


                    <Select
                      onChange={(event) => handleNot(event)}
                      value={selectedNot}
                      sx={{
                        width: { xs: '100%', md: '20%' },
                        borderRadius: '8px',
                        mb: { xs: 0, md: 0 },
                        ml: { xs: 0, md: 0 },
                      }}
                    >
                      <MenuItem value="น้ำปลา" >น้ำปลา</MenuItem>
                      <MenuItem value="มะนาว">มะนาว</MenuItem>
                    </Select>

                    {/* <TextField inputProps={inputremovepading}
                      sx={{
                        width: { xs: '100%', md: '20%' },
                        height: '45px',
                        borderRadius: '8px',
                        mb: { xs: 0, md: 0 },
                        ml: { xs: 0, md: 0 },
                      }}

                      placeholder="  ไม่ระบุ"
                      variant="outlined"
                      fullWidth
                      value={selectedNot}
                      onChange={handleNot}
                    /> */}

                    <TextField inputProps={inputremovepading}
                      type="number"
                      sx={{
                        width: { xs: '100%', md: '10%' },
                        height: '45px',
                        borderRadius: '8px',
                        mb: { xs: 0, md: 0 },
                        ml: { xs: 0, md: 0 },
                      }}
                      variant="outlined"
                      fullWidth
                      value={selectedProduct}
                      onChange={(e) => handleProduct(parseFloat(e.target.value))}
                    />


                    <Select
                      onChange={(event) => handleWhite(event)}
                      value={selectedWhite}
                      sx={{
                        width: { xs: '100%', md: '20%' },
                        borderRadius: '8px',
                        mb: { xs: 0, md: 0 },
                        ml: { xs: 0, md: 0 },
                      }}
                    >
                      <MenuItem value="ชิ้น" >ชิ้น</MenuItem>
                      <MenuItem value="อัน">อัน</MenuItem>
                    </Select>

                    {/* <TextField inputProps={inputremovepading}
                      sx={{
                        width: { xs: '100%', md: '20%' },
                        height: '45px',
                        borderRadius: '8px',
                        mb: { xs: 0, md: 0 },
                        ml: { xs: 0, md: 0 },
                      }}
                      placeholder="  ไม่ระบุ"
                      variant="outlined"
                      fullWidth
                      value={selectedWhite}
                      onChange={handleWhite}
                    /> */}

                    <TextField inputProps={inputremovepading}
                      type="number"
                      sx={{
                        width: { xs: '100%', md: '10%' },
                        height: '45px',
                        borderRadius: '8px',
                        mb: { xs: 0, md: 0 },
                        ml: { xs: 0, md: 0 },
                      }}
                      variant="outlined"
                      fullWidth
                      value={selectedSpecified.toString()}
                      onChange={(e) => handleSpecified(parseFloat(e.target.value))}
                    />
                  </Grid>
                </TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #E1E6EF', padding: '3px' }}>
                  <Typography sx={{ visibility: 'hidden' }}>.</Typography>
                  <TextField inputProps={inputremovepading}
                    disabled
                    type="number"
                    sx={{
                      width: { xs: '100%', md: '100%' },
                      height: '45px',
                      borderRadius: '8px',
                    }}
                    InputProps={{
                      sx: { bgcolor: '#E1E6EF' },
                    }}
                    value={selectedSpecified * selectedProduct}
                  />
                </TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', border: '1px solid #D9D9D9' }}>
                  <Button
                    onClick={handleOnclickAddList}
                    size="small"
                    color="inherit"
                    style={{ borderRadius: 4, background: '#79C447', color: '#FFFFFF' }}
                    sx={{
                      width: { xs: '100%', md: '91px' },
                      height: '40px',
                      fontSize: '15px',
                    }}>
                    <IconButton color="inherit" size="medium">
                      <SiAddthis />
                    </IconButton>
                    เพิ่ม
                  </Button>
                </TableCell>
              </TableRow>
            </>
          </TableBody>
        </Table>
      </Grid>

      <Grid xs={12} md={12} container sx={{ m: '12px' }}>
        <Grid container justifyContent='end'  >
          <Button
            onClick={onClickSubMit}
            size="small"
            color="inherit"
            style={{ borderRadius: 4, background: '#38DF67', color: '#FFFFFF' }}
            sx={{
              width: { xs: '100%', md: '33%' },
              height: '43px',
              fontSize: '15px',
            }}>
            <IconButton color="inherit" size="medium">
            </IconButton>
            ยืนยันการบันทึกค่าใช้จ่าย
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}