import {
  Badge,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Order from "../../models/order";
import { isEmpty } from "lodash";
import axios from "axios";

interface OrderItemCardProp {
  orderItems: Order[];
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export default function OrderCart(props: OrderItemCardProp) {
  const { orderItems: orderItemsCart, handleChange } = props;
  const cancelEvent = (list: any, order: any) => {
    const { order_no } = order
    const { menu_id, menu_name, option, table_name, price, amount } = list
    axios.post(`${process.env.REACT_APP_API_URL}/log/cancelorder`, {
      menu_id, menu_name, option, table_name, price, amount, order_no
    }).then(res => window.location.reload())
    console.log(order)
  }
  return (
    <>
      {!isEmpty(orderItemsCart) ? (
        <Stack sx={{ flexGrow: 1 }}>
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <Typography gutterBottom sx={{ fontSize: 16, fontWeight: 500 }}>
              รายการที่สั่งอาหารแล้ว
            </Typography>
            <List>
              {orderItemsCart.map((order: any) => order.order_list.map((list: any, idx: number) => {
                const list_badage_color: any = list.status == 0 ? `info` : list.status == 1 ? `warning` : list.status == 2 && `success`
                return (
                  <>
                    <ListItem disablePadding>
                      <Stack
                        key={idx}
                        direction="row"
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          justifyContent: "space-between",
                        }}
                      >
                        <Stack sx={{ flexGrow: 1 }}>
                          <ListItemText
                            primary={`${list.menu_name} x${list.amount}`}
                          // secondary={orderItemCart.spicyLevel}
                          />
                          {
                            JSON.parse(list.option).map((option: any) => {
                              return (
                                <ListItemText secondary={`- (${Object.keys(option)[0]}) ${option[Object.keys(option)[0]]}`} />
                              )
                            })
                          }
                        </Stack>
                        <Stack>
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            <Typography
                              sx={{ fontSize: 12, fontWeight: 700, mr: 2 }}
                              color="secondary.light"
                            >
                              {
                                list.status == 0 ? `รับออเดอร์` : list.status == 1 ? `กำลังเสิร์ฟ` : list.status == 2 && `ได้รับอาหารเรียบร้อยแล้ว`
                              }
                              {/* {
                                list.status == 1 && `กำลังเสิร์ฟ`
                              }
                              {
                                list.status == 2 && `ได้รับอาหารเรียบร้อยแล้ว`
                              } */}
                            </Typography>
                            <Badge
                              badgeContent=" "
                              color={list_badage_color}
                              sx={{
                                ".MuiBadge-badge": {
                                  width: 12,
                                  height: 12,
                                  minWidth: "12px !important",
                                  color: "white !important",
                                },
                              }}
                            />
                          </Stack>
                          <Typography sx={{ fontSize: 18, fontWeight: 700 }} style={{
                            textAlign: "right"
                          }}>
                            ฿ {list.price}
                          </Typography>
                          <Button variant="contained" onClick={() => cancelEvent(list, order)} >ยกเลิก order</Button>
                        </Stack>
                      </Stack>
                    </ListItem>
                    <Divider sx={{ my: 2 }} />
                  </>
                )
              }))}
            </List>
          </Box>
          {/* <List sx={{ p: 2 }}>
            {orderItemsCart.map((orderItemCart) => (
              <>
                <ListItem disablePadding>
                  <Stack
                    key={orderItemCart.id}
                    direction="row"
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack sx={{ flexGrow: 1 }}>
                      <ListItemText
                        primary={
                          orderItemCart.title + `(${orderItemCart.amount})`
                        }
                      />
                    </Stack>
                    <Stack>
                      <Stack direction="row" sx={{ alignItems: "center" }}>
                        <Typography
                          sx={{ fontSize: 12, fontWeight: 700, mr: 2 }}
                          color="secondary.light"
                        >
                          ได้รับอาหารแล้ว
                        </Typography>
                        <Badge
                          badgeContent=" "
                          color="success"
                          sx={{
                            ".MuiBadge-badge": {
                              width: 12,
                              height: 12,
                              minWidth: "12px !important",
                              color: "white !important",
                            },
                          }}
                        />
                      </Stack>
                      <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
                        ฿ {orderItemCart.price}
                      </Typography>
                    </Stack>
                  </Stack>
                </ListItem>
                <Divider sx={{ my: 2 }} />
              </>
            ))}
          </List> */}
          <Box sx={{ p: 4, flexGrow: 0 }}>
            <Button
              onClick={(e) => handleChange(e, 0)}
              sx={{
                height: "60px",
                width: "100%",
                flexGrow: 1,
                display: "flex",
                color: "white",
              }}
              variant="contained"
            >
              สั่งอาหารเพิ่ม
            </Button>
          </Box>
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
}
