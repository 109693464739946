import { Autocomplete as AutocompleteBox, Chip, TextField } from "@mui/material";
import React from "react";

interface Props {
    stocks: any[]
    barcode: string
    onBlur: (newValue: string) => void;
    onChange: (newValue: string) => void;
    onDelete: () => void;
}

export default function Autocomplete(props: Props) {

    const [value, setValue] = React.useState<string | null>(props.barcode);
    const [inputValue, setInputValue] = React.useState('');

    return (
        <AutocompleteBox
            id="free-solo-demo"
            freeSolo
            value={props.barcode}
            onBlur={(e:any) => {
                props.onBlur(e.target.value)
            }}
            onChange={(event: any, newValue: string | null | any) => {
                // searchBarcode(newValue, index)
                setValue(newValue);
                props.onChange(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue, reason) => {
                setInputValue(newInputValue);
                if (reason == 'clear') {
                    props.onDelete();
                }
            }}
            options={props.stocks.map((stock) => stock.barcode)}
            renderInput={(params) => <TextField {...params} label="Barcode" />}
        />
    )
}