import React, { ChangeEvent, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LuClipboardList } from "react-icons/lu";
import { GoChecklist } from "react-icons/go";
import { SiAddthis } from "react-icons/si";

export default function Type() {
      
        const data = [
          { date: '07/01/2024', name: 'Lorem', contract: 'ไม่ระบุ', list: '1', cost: 183.50,  handle: '',},
          { date: '07/01/2024', name: 'Lorem', contract: 'ไม่ระบุ', list: '1', cost: 183.50,  handle: '',},
          // Add more data as needed
        ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container sx={{ flexGrow: 1, width: '100%'}}>
      <Grid item direction="row" justifyContent="flex-start" alignItems="center" xs={12} md={12} sx={{ bgcolor: "#FFB802", 
      width: "100%", 
      mb: 2, 
      mt: 0,
      ml: { xs: 0, md: 0},
      p: 3,
      }}>
      <Typography sx={{ color: "#FFFFFF", fontSize: { xs: '18px', md: '24px' } }}>
        ประเภทรายจ่าย
      </Typography>
    </Grid>

    <Paper sx={{ width: { xs: '95%', md: '98%' }, p: 5, ml: { xs: 1, md: 2}, mt: -1 }}>
  <Grid container spacing={2}>
    <Grid item xs={12} md={3} sx={{mt: {xs: 0 , md: -1 }, ml:{xs: 0, md: 137}}}>
      <Button
        size="large"
        color="inherit"
        style={{ borderRadius: 4, background: '#79C447', color: '#FFFFFF',padding: '9px 10px 9px 10px' }}
        sx={{
          width: {xs: '100%', md: '91px'},
          height: '40px',
          fontSize: '15px',
        }}
      >
        <IconButton color="inherit" size="medium">
        <SiAddthis />
        </IconButton>
        เพิ่ม
      </Button>
    </Grid>
        </Grid>

</Paper>


<Grid item xs={12} md={12} sx={{ mt: 2, overflowX: 'auto', marginLeft: { xs: 1, sm: 2, md: 2 } }}>
        <Table sx={{ borderRadius: '8px', width: { xs: '100%', sm: '100%', md: '99%' } }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', borderRadius: '8px 0px 0px 0px' }}>วันที่จ่าย</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>หัวข้อ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>บริษัทคู่ค้า</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>รายการ</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px' }}>ยอดเงิน</TableCell>
              <TableCell style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', backgroundColor: '#E5E5E5', padding: '12px 8px', borderRadius: '0px 8px 0px 0px' }}>จัดการ</TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={isMobile ? { flexDirection: 'column' } : {}}>
            {data.map((row, index) => (
              <TableRow key={index} sx={isMobile ? { flexDirection: 'column' } : {}}>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.date}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.name}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.contract}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.list}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>{row.cost}</TableCell>
                <TableCell style={{ textAlign: 'center', fontSize: '18px', padding: '12px 8px', border: '1px solid #D9D9D9' }}>
                  <IconButton color="primary" aria-label="edit">
                  <GoChecklist style={{color: '#FFFFFF', background: '#FFB969',width: '37.77px', height:'36.94px', borderRadius: '8px'}}/>
                  </IconButton>
                  <IconButton color="secondary" aria-label="delete">
                  <LuClipboardList style={{color: '#FFFFFF', background: '#B8DDFF',width: '37.77px', height:'36.94px', borderRadius: '8px'}}/>
                  </IconButton>
                  <IconButton color="default" aria-label="view">
                    <DeleteOutlineIcon style={{color: '#FFFFFF', background: '#FF9696',width: '37.77px', height:'36.94px', borderRadius: '8px'}}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Grid>
    </Grid>
  );
}