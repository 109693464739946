import React, { ChangeEvent, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import axios from "axios";
const options: any = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
  timeZone: 'Asia/Bangkok' // กำหนด timezone ตามต้องการ
};
export default function List() {
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedReceipt, setSelecteReceipt] = useState<string>("");
  const [selectedEmployee, setSelectedEmployee] = useState<string>("");
  const [listSaleProducts, setListSaleProducts] = useState([] as any);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const sort = (data: any) => data.sort((a: any, b: any) => a.amount - b.amount)

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(event.target.value);
  };

  const handleReceipt = (event: ChangeEvent<HTMLInputElement>) => {
    setSelecteReceipt(event.target.value);
  };

  const handleEmployee = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedEmployee(event.target.value);
  };

  const getListSaleProducts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/reports/sale-products`)
      .then((response) => {
        setListSaleProducts(response.data.response);
        console.log(response.data.response);
      });
  };

  useEffect(() => {
    getListSaleProducts();
  }, []);

  return (
    <Grid container sx={{ flexGrow: 1, width: "100%" }}>
      <Grid
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        md={12}
        sx={{
          bgcolor: "#FFB802",
          width: "100%",
          mb: 2,
          mt: 0,
          ml: { xs: 0, md: 0 },
          p: 3,
        }}
      >
        <Typography
          sx={{ color: "#FFFFFF", fontSize: { xs: "18px", md: "24px" } }}
        >
          รายการขายสินค้า
        </Typography>
      </Grid>

      <Paper
        sx={{
          width: { xs: "95%", md: "98%" },
          p: 6,
          ml: { xs: 1, md: 2 },
          mt: -1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}
            sx={{ mt: { xs: 0, sm: 0, md: -5, lg: -4 }, ml: { xs: 0, sm: 0, md: -5, lg: -5 } }}
          >
            <Typography>เลขที่ใบเสร็จ</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "291px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              variant="outlined"
              fullWidth
              value={selectedReceipt}
              onChange={handleReceipt}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            sx={{ mt: { xs: 0, sm: 0, md: -11, lg: -11 }, ml: { xs: 0, sm: 0, md: 35, lg: 33 } }}
          >
            <Typography>บาร์โค้ดสินค้า</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "291px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              variant="outlined"
              fullWidth
              value={selectedEmployee}
              onChange={handleEmployee}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            sx={{ mt: { xs: 0, sm: 0, md: -1, lg: -11 }, ml: { xs: 0, sm: 0, md: -5, lg: 72 } }}
          >
            <Typography>วันที่</Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "143px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span>12/01/2024</span>
                  <CalendarMonthOutlinedIcon
                    sx={{ ml: 1, color: "action.active" }}
                  />
                </Box>
              }
              variant="outlined"
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            sx={{ mt: { xs: 0, sm: 3, md: 2, lg: -8 }, ml: { xs: 0, sm: 0, md: -20, lg: 93 } }}
          >
            <Typography></Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "143px" },
                height: "42px",
                borderRadius: "8px",
                mb: { xs: 2, md: 0 },
              }}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span>12/01/2024</span>
                  <CalendarMonthOutlinedIcon
                    sx={{ ml: 1, color: "action.active" }}
                  />
                </Box>
              }
              variant="outlined"
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}
            sx={{ mt: { xs: 0, sm: 0, md: 1, lg: -8 }, ml: { xs: 0, sm: 0, md: -5, lg: 115 } }}
          >
            <Button
              size="small"
              color="inherit"
              style={{
                borderRadius: 4,
                background: "#FF9B28",
                color: "#FFFFFF",
                padding: "9px 10px 9px 10px",
              }}
              sx={{
                width: { xs: "100%", md: "151px" },
                height: "42px",
                fontSize: "15px",
              }}
            >
              ค้นหา
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        item
        xs={12}
        md={12}
        sx={{ mt: 2, overflowX: "auto", marginLeft: { xs: 1, sm: 2, md: 2 } }}
      >
        <Table
          sx={{
            borderRadius: "8px",
            width: { xs: "100%", sm: "100%", md: "99%" },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderRadius: "8px 0px 0px 0px",
                }}
              >
                วันที่/เวลา
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                เลขที่ใบเสร็จ
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                รายการสินค้า
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                ราคาขาย
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                จำนวนขาย
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                }}
              >
                ส่วนลดรายการ
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "600",
                  backgroundColor: "#E5E5E5",
                  padding: "12px 8px",
                  borderRadius: "0px 8px 0px 0px",
                }}
              >
                จำนวนเงินสุทธิ
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={isMobile ? { flexDirection: "column" } : {}}>
            {listSaleProducts?.map((row: any, index: number) => JSON.parse(row.list).map((lists: any) => (
              <TableRow
                key={index}
                sx={isMobile ? { flexDirection: "column" } : {}}
              >
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {new Date(row.createdAt).toLocaleString('en-US', options)}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.id}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {lists.product_name}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {lists.price}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {lists.amount}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {row.discont}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    padding: "12px 8px",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  {(lists.price * lists.amount) - (lists.price / 100 * row.discont)}
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
