export default function TaskList() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7019 15.5931C20.7389 13.3872 20.7052 11.3122 20.5945 8.80971C20.5414 7.609 20.5148 7.00865 20.2494 6.54856C20.0636 6.22646 19.7904 5.95427 19.4734 5.77553C19.0392 5.53073 18.4833 5.52065 17.4066 5.52024M9.19228 5.52023C8.11452 5.52063 7.5585 5.53048 7.12427 5.77498C6.80737 5.95343 6.53443 6.22485 6.34842 6.54652C6.08286 7.00577 6.05534 7.60593 6.00032 8.80625C5.81931 12.7549 5.82863 15.6276 6.0183 19.5816C6.0702 20.6636 6.09615 21.2045 6.3345 21.6427C6.49831 21.9439 6.75344 22.22 7.03596 22.4018C7.44703 22.6663 7.95348 22.7122 8.96638 22.804C10.3852 22.9326 11.6717 23.0029 12.9455 23.0145M10.7558 7.11221H15.8431C16.686 7.11221 17.3693 6.39945 17.3693 5.52022C17.3693 4.64098 16.686 3.92822 15.8431 3.92822H10.7558C9.91295 3.92822 9.22965 4.64098 9.22965 5.52022C9.22965 6.39945 9.91295 7.11221 10.7558 7.11221Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M15.4141 20.869L17.4096 22.963C17.4765 23.0332 17.5944 23.034 17.6624 22.9648L21.7767 18.7744"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M9.58154 12.147H17.0046"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M9.58154 15.8584H14.8838"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}
