export default function Back() {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66668 7.99998L1.33334 4.66665L4.66668 1.33331"
        fill="#FF7622"
      />
      <path
        d="M4.66668 7.99998L1.33334 4.66665L4.66668 1.33331"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
