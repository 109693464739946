import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Navbar from "./layouts/Navbar";
import Header from "./layouts/Header";
import Cookies from "universal-cookie";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inhe qt" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Inter", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      light: "#FEB34F",
      main: "#FF7622",
    },
    secondary: {
      light: "#828282",
      main: "#181C2E",
      dark: "#000000",
    },
    warning: {
      main: "#FFB802",
    },
    success: {
      main: "#38DF67",
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: "#FEB34F",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#FEB34F",
        },
      },
    },
  },
});

export default function App() {
  const location = useLocation();
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();

  const [hasPermission, setHasPermission] = React.useState<boolean>(false);

  const [open, setOpen] = React.useState<boolean>(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  
  const [employeeStatus, setEmployeeStatus] = React.useState<boolean>(false);

  React.useEffect(() => {
    const _token = cookies.get('employee_token')
    if (_token) {
      axios.post(`${process.env.REACT_APP_API_URL}/employee/check`, {}, {
        headers: {
          authorization: _token
        }
      }).then((response) => {
        // console.log(response.data)
        if (response.data.message == 'Token is vailded') {
          setEmployeeStatus(true);
        }
        else {
          navigate('/login')
        }
      })
    }
    else {
      navigate('/login')
    }
  }, []);

  React.useEffect(() => {
    setHasPermission(false);
    checkPermission();
  }, [location.pathname])

  const checkPermission = () => {
    const _token = cookies.get('employee_token')
    axios.post(`${process.env.REACT_APP_API_URL}/employee/check/permission`, {
      pathname: location.pathname
    }, {
      headers: {
        authorization: _token
      }
    }).then((response) => {
      if (response.data.message == 'OK') {
        setHasPermission(true);
      }
      else {
        setHasPermission(false);
      }
    })
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Header toggleDrawer={toggleDrawer} open={open}></Header>
        <Navbar toggleDrawer={toggleDrawer} open={open} ></Navbar>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <main>
            {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} > */}
            {
              hasPermission ? <Outlet></Outlet> : <></>
            }


            {/* <Grid container spacing={3}>

              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >

                </Paper>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <Paper
                   sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >

                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                </Paper>
              </Grid>
            </Grid> */}
            {/* <Copyright sx={{ pt: 4 }} /> */}
            {/* </Container> */}
          </main>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
