import {
    Badge,
    Box,
    Button,
    Grid,
    Tab,
    Tabs,
    Typography,
    styled,
  } from "@mui/material";
  import { createTheme, ThemeProvider } from "@mui/material/styles";
  import "./../../index.css";
  import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
  
  import React, { useEffect, useState } from "react";
  import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
  import Order from "../../models/order";
  import Menu from "../../models/menu";
  import OrderButtonList from "../../components/order/OrderButtonList";
  import OrderList from "../../components/order/OrderList";
  import OrderTabList from "../../components/order/OrderTabList";
  import OrderHistory from "../../components/order/OrderHistory";
  import OrderCart from "../../components/order/OrderCart";
  import { io } from "socket.io-client";
  import axios from "axios";
  import Cookies from "universal-cookie";
  const cookies = new Cookies(null, { path: '/' });
  const socket = io(`${process.env.REACT_APP_API_URL}`, {
    withCredentials: true,
  });
  
  const defaultTheme = createTheme({
    typography: {
      fontFamily: ["Inter", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        light: "#FEB34F",
        main: "#FF7622",
      },
      secondary: {
        light: "#828282",
        main: "#181C2E",
        dark: "#000000",
      },
      warning: {
        main: "#FFB802",
      },
      success: {
        main: "#38DF67",
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          colorPrimary: {
            color: "#FEB34F",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#FEB34F",
          },
        },
      },
    },
  });
  
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  const CallServiceStaff = () => {
    const cookie = cookies.get('table_name')
    socket.emit('alram', cookie)
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  export default function OrderPage() {
    const cookies = new Cookies(null, { path: "/" });
    const navigate = useNavigate();
    const location = useLocation();
    const [filteredOrderItems, setfilteredOrderItems] = useState<Order[]>([]);
    const [orderItems, setOrderItems] = useState<Order[]>([]);
    const [menuItems, setMenuItems] = useState<Menu[]>([]);
    const [value, setValue] = useState(0);
    const [orderCount, setOrderCount] = useState(0);
    const [orderCartItems, setOrderCartItems] = useState<Order[]>([]);
    const [ordersHistory, setOrdersHistory] = useState([]);
  
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  
    // Category
    const getMenuItems = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/categories/get/all`)
        .then((response) => {
          setMenuItems(response.data.categories);
        });
    };
  
    // รายการอาหาร
    const getOrderItems = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/menus/get/all`)
        .then((response) => {
          setOrderItems(response.data.menus);
        });
    };
  
    const getHistoryItems = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/get/orders`, {
          headers: {
            authoriztion: cookies.get("token"),
          },
        })
        .then((response) => {
          if (response.data.message == "OK") {
            setOrdersHistory(response.data.order_list);
          }
        });
    };
  
    const onOrderChange = (count: number) => {
      setOrderCount(count);
    };
  
    useEffect(() => {
      navigate({
        search: `?${createSearchParams({
          type: "เมนูแนะนำ",
        })}`,
      });
      getOrderItems();
      getMenuItems();
      getHistoryItems();
    }, []);
  
    useEffect(() => {
      let type = new URLSearchParams(location.search).get("type") ?? "";
      let data: Order[] = [];
  
      // แก้ไขบัคเมื่อเข้ามาแล้วไม่มี TYPE ให้ไปที่หน้าเมนูแนะนำอัตโนมัติ
      if (type === "") {
        navigate({
          search: `?${createSearchParams({
            type: "เมนูแนะนำ",
          })}`,
        });
      }
  
      if (type === "เมนูแนะนำ") {
        data = orderItems.filter((item: Order) => item.isRecommended);
      } else if (type) {
        data = orderItems.filter((item: Order) => item.category_name === type);
      }
      setfilteredOrderItems(data);
    }, [location, orderItems, menuItems, localStorage.getItem("orders")]);
  
    useEffect(() => {
      setInterval(() => {
        let json = localStorage.getItem("orders");
        if (json) {
          const currOrders: Order[] = JSON.parse(json);
          setOrderCartItems(currOrders);
        }
      }, 500);
    }, []);
  
    return (
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ width: "100%", height: "100vh", backgroundColor: "white" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <OrderTabList
              orderCount={orderCount}
              value={value}
              handleChange={handleChange}
            />
          </Box>
          <CustomTabPanel value={value} index={0}>
            <OrderButtonList menuItems={menuItems} />
            <OrderList
              orderItems={filteredOrderItems}
              onOrderChange={onOrderChange}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <OrderHistory
              handleChange={handleChange}
              orderItems={ordersHistory}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <OrderCart
              orderItems={orderCartItems}
              onOrderChange={onOrderChange}
            />
          </CustomTabPanel>
  
          <Grid container justifyContent='end'>
            <Button onClick={CallServiceStaff} variant='contained' sx={{ bgcolor: "#FF0000", color: "white", borderRadius: 8, position: 'fixed', bottom: '0', margin: 2 }}>
              <NotificationsActiveIcon />
              เรียกพนักงาน
            </Button>
          </Grid>
        </Box>
      </ThemeProvider>
    );
  }
  