import { Avatar, Badge, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, Drawer, FormControl, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Toolbar, Typography } from "@mui/material"
import DiningTable2 from "../../components/Reservation/DiningTable2"
import DiningTable4 from "../../components/Reservation/DiningTable4"
import DiningTable10 from "../../components/Reservation/DiningTable10"
import DiningTable12 from "../../components/Reservation/DiningTable12"
import { AssignmentOutlined } from '@mui/icons-material';
import { useEffect, useState, useRef } from 'react';
import React from "react"
import TableOnCustomers from "./TableOnCustomers"
import { Link } from "react-router-dom"
import axios from "axios"
import { io } from "socket.io-client";
const socket = io(`${process.env.REACT_APP_API_URL}`, {
    withCredentials: true,
});
const Reservation = () => {
    const [alram, setAlram] = useState<string[]>([])
    const [openList, setOpenList] = React.useState<boolean>(false)
    const [listTable, setListTable] = React.useState([]);
    const [selectedTable, setSelectedTable] = React.useState<any>({});
    const audioRef = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    React.useEffect(() => {
        reloadTables();
        sockets();      
    }, []);

    const sockets = () => {
        socket.on("alram", (msg: any) => {
            // console.log(`alram ${msg}`)
            playAlarm();
            setAlram((prev: string[])=>[...prev,msg])
        });
    }
    
    const playAlarm = () => {
        const alramAudio:any = document.getElementById("alarm_employee");
        alramAudio.play();
    }

    const stopAlarm = () => {
        const alramAudio:any = document.getElementById("alarm_employee");
        alramAudio.pause();
        alramAudio.currentTime = 0;
    }

    const reloadTables = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/tables/get/all`).then((response:any) => {
            if (response.data.message == 'OK') {
                setListTable(response.data.tables);

                if(selectedTable.id != undefined)
                {
                    const findIdx = response.data.tables.findIndex((element:any) => {
                        return element.id == selectedTable.id
                    });

                    if(findIdx != -1)
                    {
                        console.log(response.data.tables[findIdx].customerSeats)
                        setSelectedTable({
                            id: response.data.tables[findIdx].id,
                            name: response.data.tables[findIdx].name,
                            haved_token: response.data.tables[findIdx].haved_token,
                            customerSeats: response.data.tables[findIdx].customerSeats,
                            date_generated: response.data.tables[findIdx].date_generated,
                            time_generated: response.data.tables[findIdx].time_generated
                        });
                    }
                }
            }
        })
    }

    return (
        <Grid container p={4} sx={{ bgcolor: 'white' }}>


            <React.Fragment key={'right'}>

                <Drawer
                    PaperProps={{
                        sx: { width: { xs: '100%', sm: '80%', md: '50%', lg: '35%' } },
                    }}
                    anchor={'right'}
                    open={openList}
                    onClose={() => setOpenList(false)}
                >

                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                    </Toolbar>
                    <TableOnCustomers alram={alram} setAlram={setAlram} selectedTable={selectedTable} reloadTables={reloadTables} stopAlarm={stopAlarm} />

                </Drawer>
            </React.Fragment>


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>

                <div>
                    <Typography variant="h5" component="h2">
                        โต๊ะอาหารทั้งหมด
                    </Typography>
                </div>

                <div>
                    <FormControl sx={{ mt: 2, pr: 1 }}>
                        <Button fullWidth size={"large"} style={{ backgroundColor: "#7b69e8", color: "white", borderRadius: 10 }} >ย้ายโต๊ะ / จองโต๊ะ</Button>
                    </FormControl>

                    <FormControl sx={{ mt: 2, pr: 1 }} component={Link} to={'/orderkitchen'}>
                        <Button fullWidth size={"large"} style={{ backgroundColor: "#e2e2e2", color: "black", borderRadius: 10 }} >ส่งไปยังครัว</Button>
                    </FormControl>


                </div>



            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', paddingTop: 10 }}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#3395f0',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid #91caff'
                }}></div>&nbsp;&nbsp;มีอยู่</div>
                &nbsp;&nbsp;
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#8e3dfd',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(190 151 245)'
                }}></div>&nbsp;&nbsp;จองแล้ว</div>
                &nbsp;&nbsp;

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#14c91c',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(207 238 208)'
                }}></div>&nbsp;&nbsp;เรียกเก็บเงินแล้ว</div>
                &nbsp;&nbsp;
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}><div style={{
                    background: '#f0b433',
                    width: 17,
                    height: 17,
                    borderRadius: 50,
                    border: ' 3px solid rgb(253 219 147)'
                }}></div>&nbsp;&nbsp;กำลังว่าง</div>
            </div>




            <Grid container spacing={1} p={6} rowSpacing={10} alignItems={'center'}>
                {
                    listTable.length > 0 && listTable.sort(function (a: any, b: any) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }).map((table: any) => {
                        const color_table = table.haved_token == 1 ? "#d6e9fb" : "#fff5ee"
                        if (table.seats <= 2) {
                            return (
                                <Grid item md={3}>
                                    <DiningTable2
                                        alram={alram}
                                        tableName={table.name}
                                        color={color_table}
                                        onClick={() => {
                                            setSelectedTable({
                                                id: table.id,
                                                name: table.name,
                                                haved_token: table.haved_token,
                                                customerSeats: table.customerSeats,
                                                date_generated: table.date_generated,
                                                time_generated: table.time_generated
                                            })
                                            setOpenList(true)
                                        }}
                                    ></DiningTable2>
                                </Grid>
                            )
                        }
                        else if (table.seats <= 4) {
                            return (
                                <Grid item md={3}>
                                    <DiningTable4
                                        alram={alram}
                                        tableName={table.name}
                                        color={color_table}
                                        onClick={() => {
                                            setSelectedTable({
                                                id: table.id,
                                                name: table.name,
                                                haved_token: table.haved_token,
                                                customerSeats: table.customerSeats,
                                                date_generated: table.date_generated,
                                                time_generated: table.time_generated
                                            })
                                            setOpenList(true)
                                        }}
                                    ></DiningTable4>
                                </Grid>
                            )
                        }
                        else if (table.seats <= 10) {
                            return (
                                <Grid item md={4}>
                                    <DiningTable10
                                        alram={alram}
                                        tableName={`${table.name}`}
                                        color={color_table}
                                        onClick={() => {
                                            setSelectedTable({
                                                id: table.id,
                                                name: table.name,
                                                haved_token: table.haved_token,
                                                customerSeats: table.customerSeats,
                                                date_generated: table.date_generated,
                                                time_generated: table.time_generated
                                            })
                                            setOpenList(true)
                                        }}
                                    ></DiningTable10>
                                </Grid>
                            )
                        }
                        else {
                            return (
                                <Grid item md={5}>
                                    <DiningTable12
                                        alram={alram}
                                        tableName={`${table.name}`}
                                        color={color_table}
                                        onClick={() => {
                                            setSelectedTable({
                                                id: table.id,
                                                name: table.name,
                                                haved_token: table.haved_token,
                                                customerSeats: table.customerSeats,
                                                date_generated: table.date_generated,
                                                time_generated: table.time_generated
                                            })
                                            setOpenList(true)
                                        }}
                                    ></DiningTable12>
                                </Grid>
                            )
                        }
                    })
                }
            </Grid>


            {/* <Paper style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} elevation={3} sx={{ borderRadius: 5 }}>

                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>

                    <Grid item sm={12} xs={12} md={6} lg={6} p={2}>

                        <Grid container>
                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <List sx={{ bgcolor: 'background.paper', borderRadius: 20 }} disablePadding>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <IconButton style={{ backgroundColor: '#f8f9fd', width: '40px', height: '40px', borderRadius: 7, left: '10px' }}>
                                                <AssignmentOutlined style={{ color: '#8e3dfd' }} />
                                            </IconButton>
                                        </ListItemAvatar>
                                        <ListItemText primary="Table" secondary="Order #102" />
                                    </ListItem>


                                </List>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" sx={{ mr: 2, ml: 2, display: { xs: 'none', sm: 'none', md: 'block' } }} flexItem />
                            <Grid item sm={12} xs={12} md={6} lg={6} p={2}>


                                <Badge color="secondary" badgeContent={'x'}>
                                    <Button variant="outlined">T-10</Button>
                                </Badge>
                                &nbsp;&nbsp;&nbsp;
                                <Badge color="secondary" badgeContent={'x'}>
                                    <Button variant="outlined">T-11</Button>
                                </Badge>
                            </Grid>
                        </Grid>




                    </Grid>

                    <Grid item sm={12} xs={12} md={6} lg={6} textAlign={'right'} p={2}>
                        <Button size={"large"} style={{
                            backgroundColor: "#ff6801", color: "white",
                            borderRadius: 25,
                            padding: '10px 35px 10px 35px'
                        }} >จองโต๊ะ</Button>
                    </Grid>

                </Grid>




            </Paper> */}
            <audio
                id="alarm_employee"
                style={{ display: 'none' }}
                ref={audioRef}
                controls
                autoPlay={isPlaying}
            >
                  <source src={`${process.env.REACT_APP_BASE_URL}/call_employee.mp3`} type="audio/mpeg" />
            </audio>
        </Grid >
    )
}

export default Reservation