import {
  AppBar,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { FormatListBulleted } from "@mui/icons-material";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Menu from "../../models/menu";
import { isEmpty } from "lodash";

interface MenuItemProp {
  menuItems: Menu[];
}

const CustomButton = styled(Button)(({ variant }) => ({
  backgroundColor: variant === "text" ? "transparent" : "#FF7622",
  color: variant === "text" ? "#030303" : "#ffffff",
  borderRadius: 100,
  "&:hover": {
    backgroundColor: variant === "text" ? "transparent" : "#FF7622",
  },
}));

export default function OrderButtonList(props: MenuItemProp) {
  const navigate = useNavigate();
  const { menuItems } = props;
  const [isSelected, setIsSelected] = useState<number | null>(0);

  useEffect(() => {
    if (!isEmpty(menuItems)) {
      setIsSelected(menuItems[0].id);
    }
  }, [menuItems]);

  return (
    <AppBar
      color="inherit"
      position="static"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        whiteSpace: "nowrap",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
        pb: 2,
      }}
    >
      <Container>
        {menuItems.map((item) => (
          <CustomButton
            key={item.id}
            value={item.id}
            onClick={() => {
              navigate({
                search: `?${createSearchParams({
                  type: item.name,
                })}`,
              });

              setIsSelected(item.id);
            }}
            variant={isSelected === item.id ? "contained" : "text"}
          >
            <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
              {item.name}
            </Typography>
          </CustomButton>
        ))}
      </Container>
    </AppBar>
  );
}
