import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, FormControl, Grid, IconButton, InputBase, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, TextareaAutosize, Typography } from "@mui/material";
import * as React from 'react';
import { DataGrid, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import { DeleteOutline, EditOutlined, PrivacyTipOutlined, TaskOutlined } from "@mui/icons-material";
import { FoodService } from "../../services/FoodService";
import CreateOrUpdate from "./CreateOrUpdate";
import _ from "lodash";
import Swal from 'sweetalert2';

const defaultImage = "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg";

export default function ManageFood() {

    const [isDailog, setIsDialog] = React.useState<boolean>(false);
    const [editRecord, setEditRecord] = React.useState<any>();
    const [fullWidth, setFullWidth] = React.useState<boolean>(true);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
    const [foods, setFoods] = React.useState<Array<any>>([])
    const [categorys, setCategorys] = React.useState([])
    const [routes, setRoutes] = React.useState([])

    const columns: GridColDef[] = [
        {
            field: 'imageUrl', headerName: 'รูป', headerAlign: 'center', align: 'center', flex: 1, renderCell: (row: any) => {
                return <img src={row.value || defaultImage}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = defaultImage;
                    }}
                    style={{ width: 80, height: 80, padding: 5, borderRadius: 10 }} />
            }
        },
        { field: 'id', headerName: 'รหัส', width: 50, headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'category_name', headerName: 'หมวดหมู่', width: 50, headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'route_name', headerName: 'แผนก', width: 50, headerAlign: 'center', align: 'center', flex: 1 },
        {
            field: 'name', headerName: 'รายการอาหาร', headerAlign: 'center', align: 'center', flex: 1,
        },
        {
            field: 'price', headerName: 'ราคา', headerAlign: 'center', align: 'center', flex: 1
        },
        {
            field: 'recommened', headerName: 'แนะนำ', headerAlign: 'center', align: 'center', flex: 1, renderCell: (row) => {
                return row.value ? 
                <Button size="small" variant="outlined" >แนะนำ</Button>
                :
                null
            }
        },
        {
            field: 'id1', headerName: ' ', headerAlign: 'center', align: 'center', flex: 1, renderCell: (row) => {
                return (
                    <>
                        <Button onClick={async () => {
                            setIsDialog(true)
                            setEditRecord(row.row)
                        }}>
                            <EditOutlined />
                        </Button>
                        <Button onClick={async () => deleteFood(`${row.id}`)}>
                            <DeleteOutline />
                        </Button>
                    </>
                )
            }
        }
    ];


    React.useEffect(() => {
        getFoods();
    }, [])


    const getFoods = async () => {
        const res = await FoodService.getFoods()
        if (res?.status == 200) {
            const { menus, categorys, routes } = res.response
            setFoods(menus)
            setCategorys(categorys)
            setRoutes(routes)
        }
    }

    const deleteFood = async (foodId: string) => {

        Swal.fire({
            title: "Are you sure to delete ?",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonColor: 'orange',
            confirmButtonText: "Confirm. Delete it!",
            denyButtonText: `Cancel`
        }).then(async (result) => {
            if (result.isConfirmed) {

                const res = await FoodService.deleteFood(foodId)
                if (res?.status == 200) {
                    const indexFood = _.findIndex(foods, { id: +foodId })
                    const _foods = [...foods]
                    _foods.splice(indexFood, 1)
                    setFoods(_foods)
                }
            } else if (result.isDenied) {
            }
        });


    }

    return (
        <Grid container p={4} sx={{ bgcolor: 'white' }}>

            <Dialog
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        setIsDialog(false)
                    }
                }}
                open={isDailog}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{ sx: { borderRadius: "13px" } }}

            >

                <DialogTitle id="form-dialog-title">รายละเอียด</DialogTitle>
                <Divider></Divider>
                <DialogContent sx={{ p: 3 }}>


                    <CreateOrUpdate
                        editRecord={editRecord}
                        routes={routes}
                        onClose={() => setIsDialog(false)}
                        categorys={categorys}
                        onResponse={(menu: any, isUpdate: boolean) => {

                            if (isUpdate) {
                                const _menu = menu[0]
                                const foundIndex = _.findIndex(foods, { id: +_menu.id })
                                if (foundIndex >= 0) {
                                    const _foods = [...foods];
                                    _foods[foundIndex] = _menu
                                    setFoods(_foods)
                                }
                            } else {
                                //create
                                setFoods(p => [...menu, ...p])
                            }



                        }}
                    />
                </DialogContent>
            </Dialog>

            <Grid sm={12} sx={{ bgcolor: "#FFB802", p: 3, width: "100%" }}>
                <Typography
                    sx={{
                        color: "#FFFFFF",
                        fontWeight: 600,
                        fontSize: { xs: "18px", md: "24px" },
                    }}
                >
                    เพิ่มรายการอาหาร
                </Typography>
            </Grid>

            <Box pt={2} pb={2} display={'flex'} justifyContent={'flex-end'} >
                <Button
                    onClick={() => {
                        setIsDialog(true)
                        setEditRecord({})
                    }}
                    variant="contained" style={{ background: '#13ad13', color: 'white' }}>
                    + รายการอาหาร
                </Button>
            </Box>

            <Box sx={{ width: { xs: 700, sm: 700, md: '100%' } }} style={{ overflow: 'auto' }} >
                <DataGrid
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 50, 100, 500]}
                    sx={{ width: { xs: 700, sm: 700, md: '100%' } }}
                    getRowId={(row: GridRowModel) => row.id}
                    getRowHeight={() => 'auto'}
                    disableEval
                    showCellVerticalBorder={true}
                    disableColumnMenu
                    disableColumnFilter
                    rows={foods}
                    columns={columns}
                />
            </Box>
        </Grid >
    )
}