import { Badge, Box, Tab, Tabs, Typography } from "@mui/material";

interface OrderTabListProp {
  orderCount: number;
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OrderTabList(props: OrderTabListProp) {
  const { orderCount, value, handleChange } = props;

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        sx={{
          height: 60,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
        centered
      >
        <Tab
          sx={{
            height: 100,
            "&.Mui-selected": {
              color: "#FEB34F",
            },
          }}
          label={<Typography>รายการอาหาร</Typography>}
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            height: 100,
            "&.Mui-selected": {
              color: "#FEB34F",
            },
          }}
          label={<Typography>ประวัติการสั่ง</Typography>}
          {...a11yProps(1)}
        />
        <Tab
          sx={{
            height: 100,
            "&.Mui-selected": {
              color: "#FEB34F",
            },
          }}
          label={
            <Badge
              badgeContent={orderCount}
              color="error"
              sx={{
                "& .MuiBadge-badge": {
                  right: -6,
                  top: -3,
                  padding: 0,
                  fontSize: 10,
                },
              }}
            >
              <Typography>ตะกร้าอาหาร</Typography>
            </Badge>
          }
          {...a11yProps(2)}
        />
      </Tabs>
    </Box>
  );
}
