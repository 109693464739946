import { Axios } from './../shared/Axios';
import qs from 'qs';

class DashboardService extends Axios {

    private static instance: DashboardService;

    static get(): DashboardService {
        if (!DashboardService.instance) {
            DashboardService.instance = new DashboardService();
        }
        return DashboardService.instance;
    }

    async getDashboard(): Promise<any> {
        return this.instance.get(`/dashboard`)
    }



}


const dashboardService = DashboardService.get();

export { dashboardService as DashboardService }