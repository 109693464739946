import OrderPage from "./OrderPage";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import axios from "axios";

const OrderIndex = () => {
  const cookies = new Cookies(null, { path: "/" });
  const [statusToken, setStatusToken] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if(cookies.get('token') != undefined)
    {
      axios.get(`${process.env.REACT_APP_API_URL}/token/check/`, {
        headers: {
            token: cookies.get('token')
        }
      }).then((response) => {
        if(response.data.message === 'Token is vailded')
        {
          setStatusToken(true);
          setLoading(false);
        }
        else
        {
          cookies.remove('token');
          setStatusToken(false);
          setLoading(false);
        }
      });
    }
    else
    {
      setStatusToken(false);
      setLoading(false);
    }
  }, [])

  return statusToken ? <OrderPage /> : <>
    {
      loading ? "กำลังโหลดข้อมูล..." : "QRcode ของคุณหมดอายุแล้ว หรือ คุณไม่ได้เข้าสู่ระบบอย่างถูกต้อง :("
    }
  </>
}

export default OrderIndex;