import { Axios } from './../shared/Axios';
import qs from 'qs';

class FoodService extends Axios {

    private static instance: FoodService;

    static get(): FoodService {
        if (!FoodService.instance) {
            FoodService.instance = new FoodService();
        }
        return FoodService.instance;
    }

    async getFoods(): Promise<any> {
        return this.instance.get(`/foods`)
    }

    async getFoodOptions(menuId: string): Promise<any> {
        return this.instance.get(`/foods/options/${menuId}`)
    }

    async saveFood(formData: object): Promise<any> {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': "application/json"
            }
        };
        return this.instance.post(`/foods`, formData, config)
    }

    async deleteFood(menuId: string): Promise<any> {
        return this.instance.delete(`/foods/${menuId}`)
    }


    async updateFood(menuId: string, formData: object): Promise<any> {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': "application/json"
            }
        };
        return this.instance.patch(`/foods/${menuId}`, formData, config)
    }




}


const foodService = FoodService.get();

export { foodService as FoodService }